import React from 'react';
import { Button, Form } from 'react-bootstrap';


const formatDateForInput = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const AdminHelpEdit = ({ selectedHelp, onSave, onCancel, onChange, onDelete }) => {
  const helpTypes = ["GİYİM", "HİJYEN", "GIDA KOLİSİ", "KAVURMA", "HASTA VEYA ÇOCUK BEZİ", "SEVGİ BOHÇASI", "OKUL ÇANTASI", "SANAL BÜTÇE"];

  if (!selectedHelp) {
    return <p>Yardım bilgisi yükleniyor...</p>;
  }

  return (
    <div>
      <h4>YARDIM GÜNCELLEME</h4>
      <Form>
        <Form.Group>
          <Form.Label>YARDIM TÜRÜ</Form.Label>
          <Form.Control
            as="select"
            value={selectedHelp.helpType || ""}
            onChange={(e) => onChange("helpType", e.target.value)}
          >
            <option value="" disabled>Yardım türünü seçin</option>
            {helpTypes.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>ADET</Form.Label>
          <Form.Control
            type="number"
            value={selectedHelp.count || ""}
            onChange={(e) => onChange("count", e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>TARİH</Form.Label>
          <Form.Control
            type="date"
            value={selectedHelp.date ? formatDateForInput(new Date(selectedHelp.date)) : ""}
            onChange={(e) => onChange("date", e.target.value)}
          />
        </Form.Group>
        <div>
          <Button variant="primary" type="button" onClick={onSave}>GÜNCELLE</Button>
          <Button variant="secondary" type="button" onClick={onCancel}>İPTAL</Button>
          <Button variant="danger" type="button" onClick={onDelete}>SİL</Button> {/* Sil butonu eklendi */}
        </div>
      </Form>
    </div>
  );
};

export default AdminHelpEdit;
