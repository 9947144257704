import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider as StoreProvider } from 'react-redux';
import store from './store';
import "./assets/scss/styles.scss";
import 'primereact/resources/themes/saga-blue/theme.css'; // Temanızı buraya ekleyin
import 'primereact/resources/primereact.min.css'; // PrimeReact temel stilleri
import 'primeicons/primeicons.css'; // PrimeIcons (ikonlar) için gerekli sti

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
    <App />
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
