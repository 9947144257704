import React from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { useAppSelector } from "../../../store/hooks";
import PasswordForm from "./password-form";
import ProfileForm from "./profile-form";

const Profile = () => {
  const user = useAppSelector((state) => state.auth.user);
  const { ad, soyad, dogumTarihi, builtIn } = user;


  return (
    <Container>
      <Row className="g-5">
        <Col lg={2} className="text-center">
          <FaUserCircle size="120" />
          <h4>
            {ad} {soyad}
          </h4>
          <em>{dogumTarihi}</em>
          {builtIn && (
            <Alert variant="warning mt-5">
              Bu hesap değiştirilemez
            </Alert>
          )}
        </Col>
        <Col lg={5}>
          <h3>Profili Güncelle</h3>
          <ProfileForm />
          {/* Boşluk oluşturmak için ekstra bir Row ekliyoruz */}
          <Row className="mt-4"> {/* mt-4: margin-top: 1.5rem; */}
            <Col>
              <p style={{textAlign:'center', fontSize: '0.8rem'}}>
                Kullanıcı Kimlik Bilgileri ve Başvuru Yer Bilgi değişiklikleri yapamaz.
                Yapmak isteyenlerin İlçe Temsilcilikleri ile görüşme yapmaları gerekmektedir.
              </p>
            </Col>
          </Row>
        </Col>
        <Col lg={5}>
          <h3>Şifreyi Güncelle</h3>
          <PasswordForm />
        </Col>
      </Row>
    </Container>
  );
};


export default Profile;
