import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

const AdminHelpAdd = ({ onAddHelp, onCancel, userId }) => {  // userId prop olarak alınıyor
  const helpTypes = ["GİYİM", "HİJYEN", "GIDA KOLİSİ", "KAVURMA", "HASTA VEYA ÇOCUK BEZİ", "SEVGİ BOHÇASI", "OKUL ÇANTASI", "SANAL BÜTÇE"];

  const [newHelp, setNewHelp] = useState({
    helpType: "",
    count: 0,
    date: ""
  });

  const handleInputChange = (field, value) => {
    setNewHelp({ ...newHelp, [field]: value });
  };

  const handleSave = () => {
    // userId'yi newHelp'e ekleyip onAddHelp'e gönderiyoruz
    onAddHelp({ ...newHelp, userId });
  };

  return (
    <div>
      <h4>YARDIM EKLE</h4>
      <Form>
        <Form.Group>
          <Form.Label>YARDIM TÜRÜ</Form.Label>
          <Form.Control
            as="select"
            value={newHelp.helpType}
            onChange={(e) => handleInputChange("helpType", e.target.value)}
          >
            <option value="" disabled>Yardım türünü seçin</option>
            {helpTypes.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>ADET</Form.Label>
          <Form.Control
            type="number"
            value={newHelp.count}
            onChange={(e) => handleInputChange("count", e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>TARİH</Form.Label>
          <Form.Control
            type="date"
            value={newHelp.date}
            onChange={(e) => handleInputChange("date", e.target.value)}
          />
        </Form.Group>
        <div>
          <Button variant="primary" type="button" onClick={handleSave}>Kaydet</Button>
          <Button variant="secondary" type="button" onClick={onCancel}>İptal</Button>
        </div>
      </Form>
    </div>
  );
};

export default AdminHelpAdd;
