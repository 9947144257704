import React from "react";
import "./unauthorized.scss";

const Unauthorized = () => {
  return (
    <div className="unauthorized">
      <h2>Üzgünüm! Bir şeyler yanlış gitti.</h2>
      <p>Aradığınız sayfa için yetkili değilsiniz</p>
    </div>
  );
};

export default Unauthorized;
