import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useParams, useNavigate } from "react-router-dom";
import { getHelpsByUserTc, updateHelpById, addHelp, deleteHelp } from "../../../api/helps-service";
import { Container, Row, Col, Alert, Spinner, ButtonGroup, Button } from "react-bootstrap";
import { getUserByTc } from "../../../api/user-service";
import { FaUserCircle } from "react-icons/fa";
import { toast } from "../../../helpers/functions/swal";
import AdminHelpEdit from "./admin-helps-edit"; 
import AdminHelpAdd from "./admin-helps-add"; 
import SummaryBox from './help-summary';
import { BiArrowBack } from "react-icons/bi";
import './admin-help.css';

const UserHelps = () => {
  const { tc } = useParams(); 
  const [helps, setHelps] = useState([]);
  const [filteredHelps, setFilteredHelps] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [selectedHelp, setSelectedHelp] = useState(null);
  const [addingHelp, setAddingHelp] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false); // Butonları disable etmek için eklendi
  const navigate = useNavigate();

  const columns = [
    { name: "YARDIM TÜRÜ", selector: (row) => row.helpType, sortable: true },
    { name: "ADET", selector: (row) => row.count, sortable: true },
    { name: "TARİH", selector: (row) => row.date, format: (row) => new Date(row.date).toLocaleDateString("tr-TR"), sortable: true },
  ];

  useEffect(() => {
    const loadUser = async () => {
      try {
        const userResponse = await getUserByTc(tc);
        setUser(userResponse.data);
      } catch (err) {
        console.error("Kullanıcı bilgileri yüklenirken hata oluştu:", err);
      } finally {
        setUserLoading(false);
      }
    };

    const loadHelps = async () => {
      try {
        const helpsResponse = await getHelpsByUserTc(tc);
        const sortedHelps = helpsResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setHelps(sortedHelps);
        setFilteredHelps(sortedHelps);
      } catch (err) {
        console.error("Yardım verileri yüklenirken hata oluştu:", err);
      } finally {
        setLoading(false);
      }
    };
    
    loadUser();
    loadHelps();
  }, [tc]);

  useEffect(() => {
    const filtered = helps.filter((help) => help.helpType ? help.helpType.toLowerCase().includes(filterText.toLowerCase()) : false);
    setFilteredHelps(filtered);
  }, [filterText, helps]);

  const handleRowClicked = (help) => {
    if (help && help.id) {
      setSelectedHelp(help); 
    } else {
      toast("Yardımın ID değeri eksik!", "error");
    }
  };

  const handleUpdate = async () => {
    if (selectedHelp && selectedHelp.id) {
      setIsProcessing(true); // İşlem sırasında disable
      try {
        await updateHelpById(selectedHelp.id, selectedHelp);
        toast("Yardım başarılı bir şekilde güncellendi", "success");
        setHelps(helps.map((help) => (help.id === selectedHelp.id ? selectedHelp : help)));
        setFilteredHelps(filteredHelps.map((help) => (help.id === selectedHelp.id ? selectedHelp : help)));
        setUpdateCount(prev => prev + 1);
        setSelectedHelp(null);
      } catch (err) {
        toast(err.response.data.message, "error");
      } finally {
        setIsProcessing(false); // İşlem tamamlandığında enable
      }
    } else {
      toast("Lütfen geçerli bir yardım seçin!", "warning");
    }
  };

  const handleCancel = () => {
    setSelectedHelp(null);
  };

  const handleInputChange = (field, value) => {
    setSelectedHelp({ ...selectedHelp, [field]: value });
  };

  const loadHelps = async () => {
    try {
      const helpsResponse = await getHelpsByUserTc(tc);
      const sortedHelps = helpsResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setHelps(sortedHelps);
      setFilteredHelps(sortedHelps);
    } catch (err) {
      console.error("Yardım verileri yüklenirken hata oluştu:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddHelp = async (newHelp) => {
    setIsProcessing(true); // İşlem sırasında disable
    try {
        await addHelp(newHelp);  // response'ı kullanmadık, sadece await ile çağrıyı yapıyoruz
        toast("Yeni yardım başarılı bir şekilde eklendi", "success");
        setAddingHelp(false);
        await loadHelps();
        setUpdateCount(prev => prev + 1);
    } catch (err) {
        toast(err.response.data.message, "error");
    } finally {
        setIsProcessing(false); // İşlem tamamlandığında enable
    }
};

  const handleDelete = async () => {
    if (selectedHelp && selectedHelp.id) {
      setIsProcessing(true); // İşlem sırasında disable
      try {
        await deleteHelp(selectedHelp.id);
        toast("Yardım başarıyla silindi", "success");
        setHelps(helps.filter((help) => help.id !== selectedHelp.id));
        setFilteredHelps(filteredHelps.filter((help) => help.id !== selectedHelp.id));
        setUpdateCount(prev => prev + 1);
        setSelectedHelp(null);
      } catch (err) {
        toast(err.response?.data?.message || "Silme işlemi sırasında bir hata oluştu.", "error");
      } finally {
        setIsProcessing(false); // İşlem tamamlandığında enable
      }
    } else {
      toast("Lütfen silinecek bir yardım seçin!", "warning");
    }
  };

  const handleAddClick = () => {
    setAddingHelp(true); 
  };

  return (
    <Container>
      <Row className="g-5">
        <Col lg={2} className="text-center">
          <div className="d-flex flex-column align-items-center">
            <FaUserCircle size="120" />
            {userLoading ? (
              <Spinner animation="border" />
            ) : user ? (
              <>
                <h4>{user.ad} {user.soyad}</h4>
                <em>{tc}</em>
              </>
            ) : (
              <Alert variant="danger">Kullanıcı bilgisi bulunamadı!</Alert>
            )}
          </div>
        </Col>

        <Col lg={10}>
          <div className="section-container">
                  <SummaryBox tc={tc} key={updateCount} />
          </div>

          <div className="section-container mt-4">
            <h3>YARDIM EKLE</h3>
            <ButtonGroup className="mb-3">
              <Button variant="primary" onClick={handleAddClick} disabled={isProcessing}>
                YARDIM EKLE
              </Button>
              <Button variant="secondary" onClick={() => navigate(-1)} disabled={isProcessing}>
                <BiArrowBack /> GERİ
              </Button>
            </ButtonGroup>

            {addingHelp ? (
              <AdminHelpAdd
                onAddHelp={handleAddHelp}
                onCancel={() => setAddingHelp(false)}
                userId={user ? user.id : null}  
              />
            ) : selectedHelp ? (
              <AdminHelpEdit
                selectedHelp={selectedHelp}
                onSave={handleUpdate}
                onCancel={handleCancel}
                onChange={handleInputChange}
                onDelete={handleDelete}
              />
            ) : null}
          </div>

          <div className="section-container mt-4">
            <h3>YAPILAN YARDIMLAR</h3>
            <input 
              type="text" 
              placeholder="Yardım türüne göre ara..." 
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            {loading ? (
              <Spinner animation="border" />
            ) : filteredHelps.length > 0 ? (
              <DataTable
                columns={columns}
                data={filteredHelps}
                onRowClicked={handleRowClicked}
                pagination
              />
            ) : (
              <Alert variant="info">Henüz yardım verisi bulunmuyor.</Alert>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UserHelps;
