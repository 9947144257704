import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import { useParams } from 'react-router-dom';
import AdminSybfGoruntule from '../../components/admin/pdf/admin-sybf-goruntule'

const AdminSybfPage = () => {

  const { tc } = useParams();
  
  return (
    <AdminTemplate>
    <AdminSybfGoruntule tc={tc}/>

    </AdminTemplate>
  )
}

export default AdminSybfPage