import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import RegisterForm from '../../components/common/auth/register-form'

const AdminUserAddPage
 = () => {
  return (
    <AdminTemplate>

    <RegisterForm/>
    </AdminTemplate>
    
  )
}

export default AdminUserAddPage
