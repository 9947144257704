import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import Spacer from "../../../common/spacer/spacer";
import ContactInfo from "../contact-info/contact-info";
import * as Yup from "yup";
import { useFormik } from "formik";
import { sendMessage } from "../../../../api/contact-service";
import "./contact-form.scss";
import { toast } from "../../../../helpers/functions/swal";

const ContactForm = () => {
  const [loading, setLoading] = useState(false);


  const initialValues = {
    name: "",
    email: "",
    subject: "",
    body: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Lütfen adınızı giriniz"),
    email: Yup.string()
      .email("Lütfen geçerli bir email giriniz")
      .required("Lütfen emailinizi giriniz"),
    subject: Yup.string()
      .max(50, "Konu maksimum 50 karakter olmalı")
      .min(5, "Konu minimum 5 karakter olmalı")
      .required("Bir konu başlığı giriniz"),
    body: Yup.string()
      .max(200, "Mesajınız en fazla 200 karakter olmalı")
      .min(20, "Mesajınız en az 20 karakter olmalı")
      .required("Lütfen bir mesaj giriniz"),
  });

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      await sendMessage(values);
      formik.resetForm();
      toast("Mesajınız başarılı bir şekilde gönderilmiştir.","success");

    } catch (err) {
      alert(err.response.data.message);
    }
    finally{
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Container className="contact-form">
      <Row className="gy-5">
        <Col md={6}>
          <p>
            Yardım başvurusu için lütfen kayıt olunuz. Kayıt olduğunuzda sistemin tanımlı
             olduğu ilçe temsilcileri en kısa zamanda dönüş yapacaktır. Sistemin aktif olmadığı 
             ilçeler için lütfen yüz yüze başvuru yapınız. Bize bir mesajınız olduğu takdirde formu 
             doldurup yollayabilirsiniz...
          </p>
          <Spacer height={30} />
          <ContactInfo />
        </Col>
        <Col md={6}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Adınız</Form.Label>
              <Form.Control
                type="text"
                {...formik.getFieldProps("name")}
                isInvalid={formik.touched.name && !!formik.errors.name}
                isValid={formik.touched.name && !formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                {...formik.getFieldProps("email")}
                isInvalid={formik.touched.email && !!formik.errors.email}
                isValid={formik.touched.email && !formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Konu</Form.Label>
              <Form.Control
                type="text"
                {...formik.getFieldProps("subject")}
                isInvalid={formik.touched.subject && !!formik.errors.subject}
                isValid={formik.touched.subject && !formik.errors.subject}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.subject}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Mesajınız</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows="5"
                {...formik.getFieldProps("body")}
                isInvalid={formik.touched.body && !!formik.errors.body}
                isValid={formik.touched.body && !formik.errors.body}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.body}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              disabled={!(formik.dirty && formik.isValid) || loading}
            >
              {loading && <Spinner animation="border" size="sm" />} Mesajı Gönder
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
