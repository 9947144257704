import React from 'react'
import AdminTemplate from '../../templates/admin-template'

import ARFGoruntuleVeIndir from '../../components/admin/pdf/admin-arf-goruntule'

const AcikRizaFormuPage
 = () => {
  
  return (
    <AdminTemplate>
  <ARFGoruntuleVeIndir/>

    </AdminTemplate>
  )
}

export default AcikRizaFormuPage
