import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import AdminSearch from '../../components/admin/users/admin-search'

const AdminDashboardPage = () => {
  return (
    <AdminTemplate>
      <AdminSearch/>
      </AdminTemplate>
  )
}

export default AdminDashboardPage