import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./what-we-do.scss";
import neYapiyoruzImage from "../../../../assets/img/about/what-we-do.jpg";
import { FaTurkishLiraSign} from "react-icons/fa6";
import { FaCow } from "react-icons/fa6";
import { PiStudent } from "react-icons/pi";
import { AiTwotoneGift } from "react-icons/ai";
import { FaJugDetergent } from "react-icons/fa6";
import { GiHealthNormal } from "react-icons/gi";



const WhatWeDo = () => {
  return (
    <Container fluid className="what-we-do">
      <Row>
        <Col md={5}>
          <img src={neYapiyoruzImage} alt="" className="img-fluid" />
        </Col>
        <Col md={7}>
          <div>
            <h2>Biz Ne Yapıyoruz?</h2>
            <p>
              Türkiye'nin her ilçesinde yardıma ihtiyacı olan bireyleri tespit ediyoruz. En büyük hayalimiz, Türk Kızılay Derneği'ne ulaşan yardımların,
               en kısa zamanda ihtiyaç sahiplerine ulaştırılmasıdır. Bunun için bireylerin ilçe merkezlerine başvuru yapmaları gerekmektedir. İlçe merkezinde
               çalışan ve gönüllü arkadaşların tespitleri sonucunda acil ihtiyaç sahiplerine yardımlar en kısa zamanda ulaşacaktır.
            </p>
          </div>

          <Row className="props">
            <Col xl={4} sm={6}>
            <FaTurkishLiraSign /> <span>Nakdi Yardımlar</span>
            </Col>
            <Col xl={4} sm={6}>
            <PiStudent /> <span>Eğitim Yardımları</span>
            </Col>
            <Col xl={4} sm={6}>
            <FaCow /> <span>Kurban Yardımları</span>
            </Col>
            <Col xl={4} sm={6}>
            <AiTwotoneGift /><span>Gıda Kolisi Yardımı</span>
            </Col>
            <Col xl={4} sm={6}>
            <FaJugDetergent /> <span>Hijyen Malzemleri Yardımı</span>
            </Col>
            <Col xl={4} sm={6}>
            <GiHealthNormal /> <span>Sağlık Hizmetleri Yardımı </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default WhatWeDo;
