import React, { useState, useEffect } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppSelector } from "../../../store/hooks";
import { updateUser } from "../../../api/user-service";
import { toast } from "../../../helpers/functions/swal";
import validateTc from "../../../helpers/functions/validateTc";
import illist from "../../../helpers/illist.json";

const ProfileForm = () => {
  const [loading, setLoading] = useState(false);
  const user = useAppSelector((state) => state.auth.user);
  const [ilceler, setIlceler] = useState([]);
  const [mahaleler, setMahaleler] = useState([]);

  const {
    ad,
    soyad,
    tc,
    telefonNo,
    basvuruIl,
    basvuruIlce,
    basvuruMahalle,
    adresNo,
    dogumTarihi,
    aciklama,
  } = user;

    // Dogum tarihini dizi formatından string formatına çeviren fonksiyon
    const formatDateFromArray = (dateArray) => {
      if (!Array.isArray(dateArray) || dateArray.length !== 3) return '';
      const [year, month, day] = dateArray;
      return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`; // YYYY-MM-DD
    };

  const initialValues = {
    ad,
    soyad,
    tc,
    telefonNo,
    basvuruIl,
    basvuruIlce,
    basvuruMahalle,
    adresNo,
    dogumTarihi: formatDateFromArray(dogumTarihi),
    aciklama,
  };

  const validationSchema = Yup.object({
    ad: Yup.string().required("Lütfen adınızı giriniz..."),
    soyad: Yup.string().required("Lütfen soyadınızı giriniz..."),
    tc: Yup.string()
      .required("Lütfen TC kimlik numaranızı giriniz")
      .test("is-valid-tc", "Geçersiz TC kimlik numarası", (value) => validateTc(value)),
    telefonNo: Yup.string()
      .required("Lütfen telefon numaranızı giriniz.")
      .transform((value) => value.replace(/\D/g, ""))
      .matches(/^(5\d{9})$/, "Telefon numarası 10 haneli ve geçerli bir formatta olmalıdır."),
    basvuruIl: Yup.string().required("Lütfen bulunduğunuz ili giriniz."),
    basvuruIlce: Yup.string().required("Lütfen bulunduğunuz ilçeyi giriniz."),
    basvuruMahalle: Yup.string().required("Lütfen bulunduğunuz mahalleyi giriniz."),
    adresNo: Yup.string().required("İl yerleşim belgesinde yazılı adres numaranızı giriniz"),
    dogumTarihi: Yup.string().required("Lütfen doğum tarihinizi giriniz."),
   
  });



  const onSubmit = async (values) => {
    setLoading(true);
    try {
      // Telefon numarasını temizle
      const temizlenmisValues = {
        ...values,
        telefonNo: values.telefonNo.replace(/\D/g, ""), // sadece rakamları bırak
      
      };
  
      await updateUser(temizlenmisValues);
      toast("Profiliniz güncellendi", "success");
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (basvuruIl) {
      const filteredIlceler = illist.filter((item) => item[0] === basvuruIl).map((item) => item[1]);
      setIlceler([...new Set(filteredIlceler)]);

      if (basvuruIlce) {
        const filteredMahaleler = illist.filter((item) => item[1] === basvuruIlce).map((item) => item[2]);
        setMahaleler([...new Set(filteredMahaleler)]);
      }
    }
  }, [basvuruIl, basvuruIlce]); // Kullanıcı verilerine göre ilçe ve mahalleleri ayarla

  const handleIlChange = (event) => {
    const selectedIl = event.target.value;
    formik.setFieldValue("basvuruIl", selectedIl);
    formik.setFieldValue("basvuruIlce", ""); // İlçe ve mahalleleri sıfırla
    formik.setFieldValue("basvuruMahalle", "");
    const filteredIlceler = illist.filter((item) => item[0] === selectedIl).map((item) => item[1]);
    setIlceler([...new Set(filteredIlceler)]); // Tekil ilçeleri al
    setMahaleler([]); // İl değişince mahalleleri sıfırla
  };

  const handleIlceChange = (event) => {
    const selectedIlce = event.target.value;
    formik.setFieldValue("basvuruIlce", selectedIlce);
    formik.setFieldValue("basvuruMahalle", ""); // Mahalleyi sıfırla
    const filteredMahaleler = illist.filter((item) => item[1] === selectedIlce).map((item) => item[2]);
    setMahaleler([...new Set(filteredMahaleler)]); // Tekil mahalleleri al
  };

  return (
    <Form noValidate onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Ad</Form.Label>
        <Form.Control
          type="text"
          {...formik.getFieldProps("ad")}
          isInvalid={formik.touched.ad && formik.errors.ad}
          isValid={formik.touched.ad && !formik.errors.ad}
          readOnly
        />
        <Form.Control.Feedback type="invalid">{formik.errors.ad}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Soyad</Form.Label>
        <Form.Control
          type="text"
          {...formik.getFieldProps("soyad")}
          isInvalid={formik.touched.soyad && formik.errors.soyad}
          isValid={formik.touched.soyad && !formik.errors.soyad}
          readOnly
        />
        <Form.Control.Feedback type="invalid">{formik.errors.soyad}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>TC Kimlik No</Form.Label>
        <Form.Control
          type="text"
          {...formik.getFieldProps("tc")}
          isInvalid={formik.touched.tc && formik.errors.tc}
          isValid={formik.touched.tc && !formik.errors.tc}
          readOnly
        />
        <Form.Control.Feedback type="invalid">{formik.errors.tc}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Doğum Tarihi</Form.Label>
        <Form.Control
          type="date"
          {...formik.getFieldProps("dogumTarihi")}
          isValid={formik.touched.dogumTarihi && !formik.errors.dogumTarihi}
          isInvalid={formik.touched.dogumTarihi && !!formik.errors.dogumTarihi}
          readOnly
        />
        <Form.Control.Feedback type="invalid">{formik.errors.dogumTarihi}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Telefon Numarası</Form.Label>
        <Form.Control
          type="text"
          {...formik.getFieldProps("telefonNo")}
          isInvalid={formik.touched.telefonNo && formik.errors.telefonNo}
          isValid={formik.touched.telefonNo && !formik.errors.telefonNo}
        />
        <Form.Control.Feedback type="invalid">{formik.errors.telefonNo}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Başvuru İli</Form.Label>
        <Form.Control
          as="select"
          value={formik.values.basvuruIl}
          onChange={handleIlChange}
          isValid={formik.touched.basvuruIl && !formik.errors.basvuruIl}
          isInvalid={formik.touched.basvuruIl && !!formik.errors.basvuruIl}
          readOnly
        >
          <option value=""></option>
          {[...new Set(illist.map((item) => item[0]))].map((il) => (
            <option key={il} value={il}>
              {il}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{formik.errors.basvuruIl}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Başvuru İlçe</Form.Label>
        <Form.Control
          as="select"
          value={formik.values.basvuruIlce}
          onChange={handleIlceChange}
          isValid={formik.touched.basvuruIlce && !formik.errors.basvuruIlce}
          isInvalid={formik.touched.basvuruIlce && !!formik.errors.basvuruIlce}
          readOnly
        >
          <option value=""></option>
          {ilceler.map((ilce) => (
            <option key={ilce} value={ilce}>
              {ilce}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{formik.errors.basvuruIlce}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Başvuru Mahallesi</Form.Label>
        <Form.Control
          as="select"
          value={formik.values.basvuruMahalle}
          onChange={(e) => formik.setFieldValue("basvuruMahalle", e.target.value)}
          isValid={formik.touched.basvuruMahalle && !formik.errors.basvuruMahalle}
          isInvalid={formik.touched.basvuruMahalle && !!formik.errors.basvuruMahalle}
          readOnly
        >
          <option value=""></option>
          {mahaleler.map((mahalle) => (
            <option key={mahalle} value={mahalle}>
              {mahalle}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{formik.errors.basvuruMahalle}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Adres No</Form.Label>
        <Form.Control
          type="text"
          {...formik.getFieldProps("adresNo")}
          isInvalid={formik.touched.adresNo && formik.errors.adresNo}
          isValid={formik.touched.adresNo && !formik.errors.adresNo}
          readOnly
        />
        <Form.Control.Feedback type="invalid">{formik.errors.adresNo}</Form.Control.Feedback>
      </Form.Group>

      <div className="text-end">
        <Button type="submit" variant="primary" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : "Güncelle"}
        </Button>
      </div>
    </Form>
  );
};

export default ProfileForm;
