import React, { useEffect, useState } from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { useNavigate,useParams } from 'react-router-dom';
import AdminSybf from './admin-sybf';
import { getSirByTc, getHanedekiler } from '../../../api/sir-sevice';

const AdminSybfGoruntule = ({tc}) =>  {

 
  const [data, setData] = useState({ sir: {}, hanedekiler: [] });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sirResponse = await getSirByTc(tc);
        const hanedekilerResponse = await getHanedekiler(tc);
        setData({
          sir: {
            adSoyad: sirResponse.data.adiSoyadi,
            adres: sirResponse.data.acikAdres,
            telefon: sirResponse.data.cepTelefonu,
          },
          hanedekiler: hanedekilerResponse.data,
        });
      } catch (error) {
        console.error("Veri çekilirken hata oluştu:", error);
      }
    };

    fetchData();
  }, [tc]);

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>SOSYAL YARDIM BAŞVURU FORMU</h1>

      {/* Geri Düğmesi */}
      <button onClick={() => navigate(-1)} style={{ marginBottom: '20px', padding: '10px', cursor: 'pointer' }}>
        Geri
      </button>

      {/* PDF Görüntüleyici */}
      <PDFViewer style={{ width: '100%', height: '600px' }}>
        <AdminSybf sir={data.sir} hanedekiler={data.hanedekiler} tc={tc} />
      </PDFViewer>

      {/* PDF İndir Bağlantısı */}
      <div style={{ marginTop: '20px' }}>
        <PDFDownloadLink
          document={<AdminSybf sir={data.sir} hanedekiler={data.hanedekiler} tc={tc} />}
          fileName="SosyalYardimlasmaFormu.pdf"
        >
          {({ loading }) => (loading ? 'PDF Yükleniyor...' : 'PDF İndir')}
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default AdminSybfGoruntule;
