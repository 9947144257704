import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import imageWhoWeAre from "../../../../assets/img/about/who-we-are.jpg";
import "./who-we-are.scss";
import { FaInternetExplorer } from "react-icons/fa";
import { FaWalking } from "react-icons/fa";
import { PiShoppingCartBold } from "react-icons/pi";


const WhoWeAre = () => {
  return (
    <Container className="who-we-are">
      <Row className="gy-5">
        <Col lg={4}>
          <img src={imageWhoWeAre} alt="" className="img-fluid" />
        </Col>
        <Col lg={8} className="content">
          <div>
            <h2>Biz Kimiz?</h2>
            <p>
            Kızılay'ın amacı, her nerede görülür ise , hiçbir ayrım yapmaksızın insanın acısını önlemeye veya hafifletmeye çalışmak, insanın hayatını ve sağlığını korumak, 
            onun kişiliğine saygı gösterilmesini sağlamak ve insanlar arasındaki karşılıklı anlayışı, 
            dostluğu saygıyı, işbirliğini ve sürekli barışı getirmeye uğraşmaktır. 
            Kızılay ihtiyaç anında dayanışmanın,ıstırap anında eşitliğin, savaşın en kızgın anında insancıllığın, 
            tarafsızlığın ve barışın simgesidir.
            </p>

            <p>
            Kızılay, Uluslararası Kızılay-Kızılhaç Topluluğu'nun temel ilkelerini paylaşır. Bunlar; insanlık,ayrım gözetmemek, 
            tarafsızlık, bağımsızlık,hayır kurumu niteliği, birlik ve evrensellik ilkeleridir.

            Kızılay, tüzel kişiliğe sahip, özel hukuk hükümlerine tâbi, 
            kâr amacı gütmeyen, yardım ve hizmetleri karşılıksız olan ve kamu yararına çalışan bir gönüllü sosyal hizmet kuruluşudur.
            </p>

            <p>
              Yardıma muhtaç kişilere en yakın birim ilçe merkezlerimizdir. Siz de bize ulaşın, Yalnız Kalmayın!
            </p>
          </div>

          <Row className="props">
            <Col md={4}>
            <FaInternetExplorer />
              <h4>Online Başvuru</h4>
              <p>
                Online platformu kullanan ilçe merkezlerimize ulaşmak için kayıt formunu
                doldurmanız yeterli olacaktır.
              </p>
            </Col>
            <Col md={4}>
            <FaWalking /> <h4>Yüz yüze Başvuru</h4>
              <p>
                Kızılay İlçe merkezlerine giderek sosyal yardım başvuru formunu 
                doldurarak başvurunuzu yapabilirsiniz.
              </p>
            </Col>
            <Col md={4}>
            <PiShoppingCartBold />
              <h4>Yardım Zamanı</h4>
              <p>
                Sizlere en kısa zamanda en uygun yardımlarımız gönüllülerimiz 
                tarafından ulaştırılacaktır.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default WhoWeAre;
