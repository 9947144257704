import axios from "axios";
import { settings } from "../helpers/settings";
import authHeader from "../helpers/functions/auth-header";

const API_URL = settings.apiURL;

export const register = (user) => {
  return axios.post(`${API_URL}/register`, user);
};

export const login = (credential) => {
  return axios.post(`${API_URL}/login`, credential);
};

export const getUser = () => {
  return axios.get(`${API_URL}/user`, {headers: authHeader() });
};
export const updateUser = (user) => {
  return axios.put(`${API_URL}/user`, user, {headers: authHeader() });
}

export const updatePassword = (credential) => {
  return axios.patch(`${API_URL}/user/auth`, credential, {headers: authHeader() });
}


// ADMIN ENDPOINT
export const getUsersByPage = (
  page = 0,
  size = 20,
  sort = "id",
  direction = "DESC"
) => {
  return axios.get(
    `${API_URL}/user/auth/pages?page=${page}&size=${size}&sort=${sort}&direction=${direction}`,
    { headers: authHeader() }
  );
};

export const downloadUsers = () => {
  return axios.get(`${API_URL}/api/excel/users`, {
    headers: {
      ...authHeader(),
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "blob"
  });
};


export const getUserById = (id) => {
  return axios.get(`${API_URL}/user/${id}/auth`, { headers: authHeader() });
};

export const getUserByTc = (tc) => {
  return axios.get(`${API_URL}/user/${tc}/findtc`, { headers: authHeader() });
};

export const deleteUserById = (id) => {
  return axios.delete(`${API_URL}/user/${id}/auth`, { headers: authHeader() });
};

export const updateUserById = (id, user) => {
  return axios.put(`${API_URL}/user/${id}/auth`, user, { headers: authHeader() });
};

export const searchText = (searchQuery) => {
  return axios.get(`${API_URL}/user/search?searchText=${searchQuery}`, { headers: authHeader() });
};