import axios from "axios";
import { settings } from "../helpers/settings";
import authHeader from "../helpers/functions/auth-header";

const API_URL = settings.apiURL;

export const createSir = (sir, tc) => {
  return axios.post(`${API_URL}/api/sir/${tc}`, sir, { headers: authHeader() });
};

export const updateSirById = (id, sir) => {
    return axios.put(`${API_URL}/api/sir/${id}`, sir, { headers: authHeader() });
  };

export const deleteSirById = (id) => {
    return axios.delete(`${API_URL}/api/sir/${id}`, { headers: authHeader() });
  };

  export const getSirByTc = (tc) => {
    return axios.get(`${API_URL}/api/sir/findByTc/${tc}`, {headers: authHeader() });
  };

  export const displaySybf = (tc) => {
    return axios.get(`${API_URL}/pdfreport/sybf?tcKimlikNo=${tc}`, {
      headers: {
        ...authHeader(),
        "Content-Type": "application/pdf",
      },
      responseType: "blob" // PDF dosyası olduğu için "blob" kullanılır
    });
  };

  export const displayArf = (tc) => {
    return axios.get(`${API_URL}//pdfreport/display/arf?tcKimlikNo=${tc}`, {
      headers: {
        ...authHeader(),
        "Content-Type": "application/pdf",
      },
      responseType: "blob" // PDF dosyası olduğu için "blob" kullanılır
    });
  };
  
  

  export const createHanedekiler = (tc, hanedekiler) => {
    return axios.post(`${API_URL}/api/hanede-kisiler/${tc}`, hanedekiler, { headers: authHeader() });
  };

  export const updateHanedekilerById = (id, hanedekiler) => {
    return axios.put(`${API_URL}/api/hanede-kisiler/${id}`, hanedekiler, { headers: authHeader() });
  };

  export const getKisiById = (id) => {
    return axios.get(`${API_URL}/api/hanede-kisiler/${id}`, {headers: authHeader() });
  };

  export const deletePersonById = (id) => {
    return axios.delete(`${API_URL}/api/hanede-kisiler/${id}`, { headers: authHeader() });
  };


  
  export const getHanedeYasayanlar = (tc) => {
    return axios.get(`${API_URL}/api/hanede-kisiler/${tc}`, {headers: authHeader() });
  };

  export const getHanedekiler = (tc) => {
    return axios.get(`${API_URL}/api/hanede-kisiler/edu/${tc}`, {headers: authHeader() });
  };