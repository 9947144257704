import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import ReactInputMask from "react-input-mask-next";
import * as Yup from "yup";
import { register } from "../../../api/user-service";
import { toast } from "../../../helpers/functions/swal";
import PasswordInput from "../password-input/password-input";
import illist from '../../../helpers/illist.json';
import validateTc from "../../../helpers/functions/validateTc"

const RegisterForm = ({setKey})=>{
  const [loading, setLoading] = useState(false);
  const [ilceler, setIlceler] = useState([]);
  const [mahaleler, setMahaleler] = useState([]);

  const initialValues = {
    ad: "",
    soyad: "",
    tc: "",
    dogumTarihi: "",
    sifre: "",
    sifreOnayi: "",
    telefonNo: "",
    basvuruIl: "",
    basvuruIlce: "",
    basvuruMahalle: "",
    adresNo: "",
    
  };

  const validationSchema = Yup.object({
    ad: Yup.string().required("Lütfen adınızı giriniz."),
    soyad: Yup.string().required("Lütfen soyadınızı giriniz."),
    tc: Yup.string()
    .required("Lütfen TC kimlik numaranızı giriniz")
    .test("is-valid-tc", "Geçersiz TC kimlik numarası", (value) => validateTc(value)), // TC kimlik numarası doğrulama
    sifre: Yup.string()
      .required("Lütfen şifrenizi giriniz.")
      .min(8, "Şifreniz en az 8 katakterden oluşmalı."),
    sifreOnayi: Yup.string()
      .required("Şifrenizi tekrar giriniz.")
      .oneOf([Yup.ref("sifre")], "Oluşturmak istediğiniz şifreniz eşleşmiyor."),
      telefonNo: Yup.string()
      .required("Lütfen telefon numaranızı giriniz.")
      .transform((value) => value.replace(/\D/g, "")) // Boşlukları ve maskeyi temizle
      .matches(/^(5\d{9})$/, "Telefon numarası 11 haneli ve geçerli bir formatta olmalıdır."),

    basvuruIl: Yup.string().required("Lütfen bulunduğunuz ili giriniz."),
    basvuruIlce: Yup.string().required("Lütfen bulunduğunuz ilçeyi giriniz."),
    basvuruMahalle: Yup.string().required("Lütfen bulunduğunuz mahalleyi giriniz."),
    adresNo: Yup.string().required("İl yerleşim belgesinde yazılı adres numaranızı giriniz"),
    dogumTarihi: Yup.string().required("Lütfen doğum tarihinizi giriniz."),
   
  });

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const cleanedValues = {
        ...values,
        telefonNo: values.telefonNo.replace(/\D/g, ""), // Boşlukları ve özel karakterleri temizle
      };
  
      await register(cleanedValues);  
   
      toast("Kayıt işleminiz başarı ile gerçekleşti.", "success");
      formik.resetForm();
      setKey("login");
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

// İliçeleri ve mahalleleri dinamik olarak ayarlayın
const handleIlChange = (event) => {
  const selectedIl = event.target.value;
  formik.setFieldValue("basvuruIl", selectedIl);
  formik.setFieldValue("basvuruIlce", ""); // İlçe ve mahalleleri sıfırla
  formik.setFieldValue("basvuruMahalle", "");
  const filteredIlceler = illist.filter(item => item[0] === selectedIl).map(item => item[1]);
  setIlceler([...new Set(filteredIlceler)]); // Tekil ilçeleri al
};

const handleIlceChange = (event) => {
  const selectedIlce = event.target.value;
  formik.setFieldValue("basvuruIlce", selectedIlce);
  formik.setFieldValue("basvuruMahalle", ""); // Mahalleyi sıfırla
  const filteredMahaleler = illist.filter(item => item[1] === selectedIlce).map(item => item[2]);
  setMahaleler([...new Set(filteredMahaleler)]); // Tekil mahalleleri al
};

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className="p-4">
      <Form.Group className="mb-3">
        <Form.Label>Ad</Form.Label>
        <Form.Control
          type="text"
          {...formik.getFieldProps("ad")}
          isValid={formik.touched.ad && !formik.errors.ad}
          isInvalid={formik.touched.ad && !!formik.errors.ad}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.ad}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Soyad</Form.Label>
        <Form.Control
          type="text"
          {...formik.getFieldProps("soyad")}
          isValid={formik.touched.soyad && !formik.errors.soyad}
          isInvalid={formik.touched.soyad && !!formik.errors.soyad}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.soyad}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
  <Form.Label>Doğum Tarihi</Form.Label>
  <Form.Control
    type="date"
    {...formik.getFieldProps("dogumTarihi")}
    isValid={formik.touched.dogumTarihi && !formik.errors.dogumTarihi}
    isInvalid={formik.touched.dogumTarihi && !!formik.errors.dogumTarihi}
  />
  <Form.Control.Feedback type="invalid">
    {formik.errors.dogumTarihi}
  </Form.Control.Feedback>
</Form.Group>


      <Form.Group className="mb-3">
        <Form.Label>Telefon Numarası</Form.Label>
        <Form.Control
          type="text"
          as={ReactInputMask}
          mask="999 999 9999"
          {...formik.getFieldProps("telefonNo")}
          isValid={formik.touched.telefonNo && !formik.errors.telefonNo}
          isInvalid={formik.touched.telefonNo && !!formik.errors.telefonNo}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.telefonNo}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Başvuru İli</Form.Label>
        <Form.Control
          as="select"
          value={formik.values.basvuruIl}
          onChange={handleIlChange}
          isValid={formik.touched.basvuruIl && !formik.errors.basvuruIl}
          isInvalid={formik.touched.basvuruIl && !!formik.errors.basvuruIl}
        >
          <option value="">Bir il seçiniz</option>
          {[...new Set(illist.map(item => item[0]))].map(il => (
            <option key={il} value={il}>{il}</option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {formik.errors.basvuruIl}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Başvuru İlçe</Form.Label>
        <Form.Control
          as="select"
          value={formik.values.basvuruIlce}
          onChange={handleIlceChange}
          isValid={formik.touched.basvuruIlce && !formik.errors.basvuruIlce}
          isInvalid={formik.touched.basvuruIlce && !!formik.errors.basvuruIlce}
        >
          <option value="">Bir ilçe seçiniz</option>
          {ilceler.map(ilce => (
            <option key={ilce} value={ilce}>{ilce}</option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {formik.errors.basvuruIlce}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Mahalle</Form.Label>
        <Form.Control
  as="select"
  value={formik.values.basvuruMahalle}
  onChange={(event) => {
    formik.setFieldValue("basvuruMahalle", event.target.value);
  }}
  isValid={formik.touched.basvuruMahalle && !formik.errors.basvuruMahalle}
  isInvalid={formik.touched.basvuruMahalle && !!formik.errors.basvuruMahalle}
>

          <option value="">Bir mahalle seçiniz</option>
          {mahaleler.map(mahalle => (
            <option key={mahalle} value={mahalle}>{mahalle}</option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {formik.errors.basvuruMahalle}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Adres No</Form.Label>
        <Form.Control
          type="text"
          {...formik.getFieldProps("adresNo")}
          isValid={formik.touched.adresNo && !formik.errors.adresNo}
          isInvalid={formik.touched.adresNo && !!formik.errors.adresNo}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.adresNo}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>TC Kimlik Numarası</Form.Label>
        <Form.Control
          type="tc"
          {...formik.getFieldProps("tc")}
          isValid={formik.touched.tc && !formik.errors.tc}
          isInvalid={formik.touched.tc && !!formik.errors.tc}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.tc}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Şifre</Form.Label>
        <PasswordInput
          {...formik.getFieldProps("sifre")}
          isValid={formik.touched.sifre && !formik.errors.sifre}
          isInvalid={formik.touched.sifre && !!formik.errors.sifre}
          error={formik.errors.sifre}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Şifrenizi Onaylayınız</Form.Label>
        <PasswordInput
          {...formik.getFieldProps("sifreOnayi")}
          isValid={
            formik.touched.sifreOnayi && !formik.errors.sifreOnayi
          }
          isInvalid={
            formik.touched.sifreOnayi && !!formik.errors.sifreOnayi
          }
          error={formik.errors.sifreOnayi}
        />
      </Form.Group>

      
      <Button
        variant="primary"
        type="submit"
        disabled={!(formik.dirty && formik.isValid) || loading}
      >
        {loading && <Spinner animation="border" size="sm" />} Kayıt
      </Button>
    </Form>
  );
};

export default RegisterForm;

