// HanedekilerTablo.js
import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const HanedekilerTablo = ({ hanedekiler }) => {
    return (
        <View style={styles.table}>
            {/* Başlık satırı */}
            <View style={styles.tableHeader}>
                <Text style={styles.headerCell}>TC KİMLİK NO</Text>
                <Text style={styles.headerCell}>AD SOYAD</Text>
                <Text style={styles.headerCell}>DOĞ. TARİHİ</Text>
                <Text style={styles.headerCell}>ÇALIŞMA DUR.</Text>
                <Text style={styles.headerCell}>AÇIKLAMA</Text>
                <Text style={styles.headerCell}>HAS. VEYA ENG. DUR.</Text>
                <Text style={styles.headerCell}>YAKINLIK DUR.</Text>
            </View>
            
            {/* Veri satırları */}
            {hanedekiler.map((hane, index) => (
                <View style={styles.tableRow} key={index}>
                    <Text style={styles.cell}>{hane.tcKimlikNo}</Text>
                    <Text style={styles.cell}>{hane.adSoyad}</Text>
                    <Text style={styles.cell}>{hane.dogumTarihi}</Text>
                    <Text style={styles.cell}>{hane.calismaDurumu}</Text>
                    <Text style={styles.cell}>{hane.aciklama}</Text>
                    <Text style={styles.cell}>{hane.hastalikTeshisiVeyaEngellilikDurumu}</Text>
                    <Text style={styles.cell}>{hane.yakinlikDerecesi}</Text>
                </View>
            ))}
        </View>
    );
};

export default HanedekilerTablo;

const styles = StyleSheet.create({
    table: {
        borderWidth: 1,
        borderColor: '#000',
        marginTop: 10,
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#f1f1f1',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        padding: 5,
        fontWeight: 'bold',
        borderWidth: 1,
        borderColor: '#000',
        flex: 1,
        textAlign: 'center',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    cell: {
        padding: 5,
        flex: 1,
        textAlign: 'center',
        borderWidth: 1,
        borderColor: '#000',
    },
});
