import React, { useState, useEffect, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { getAllHelps } from "../../../api/helps-service";
import '../kunye/admin-kunye.css';
import Loading from '../../common/loading/loading';

const AdminAllHelps = () => {
    const [helps, setHelps] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const formatDate = (date) => {
        return date ? date.toISOString().split('T')[0] : '';
    };

    const fetchHelps = useCallback(() => {
        setLoading(true);
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        getAllHelps(formattedStartDate, formattedEndDate)
            .then(response => {
                setHelps(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Veriler alınırken hata oluştu:", error);
                setLoading(false);
            });
    }, [startDate, endDate]);  // startDate ve endDate'e bağlı olarak fetchHelps fonksiyonu yeniden oluşturulacak

    useEffect(() => {
        fetchHelps();
    }, [fetchHelps]); // fetchHelps fonksiyonuna bağlı olarak useEffect tetiklenecek


    const onGlobalFilterChange = (e) => {
        setGlobalFilterValue(e.target.value);
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(helps);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'yardim_listesi');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], { type: EXCEL_TYPE });
            module.default.saveAs(data, fileName + EXCEL_EXTENSION);
        });
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Genel Arama" />
                </span>
                <Button icon="pi pi-file-excel" label="Excel İndir" onClick={exportExcel} className="p-button-success" />
            </div>
        );
    };

    const header = renderHeader();

    return (
        <div className="card">
            <div className="date-filter">
                <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} placeholder="Başlangıç Tarihi" />
                <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} placeholder="Bitiş Tarihi" />
                <Button label="Filtrele" onClick={fetchHelps} className="p-button-primary" />
            </div>

            {loading ? (
                <Loading />
            ) : (
                <DataTable
                    value={helps}
                    scrollable
                    scrollHeight="500px"
                    paginator
                    rows={15}
                    globalFilter={globalFilterValue}
                    header={header}
                    emptyMessage="Yardım kaydı bulunamadı."
                    resizableColumns // Sütunları yeniden boyutlandırılabilir yapar
                    showGridlines // Izgara çizgileri ekler
                    tableStyle={{ minWidth: '70rem' }}
                >
                    <Column field="tc" header="TC" style={{ minWidth: '8rem' }} />
                    <Column field="ad" header="Ad" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="soyad" header="Soyad" style={{ minWidth: '8rem' }} />
                    <Column field="telefonNo" header="Telefon" style={{ minWidth: '8rem' }} />
                    <Column field="basvuruIl" header="İl" style={{ minWidth: '8rem' }} />
                    <Column field="basvuruIlce" header="İlçe" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="helpType" header="Yardım Türü" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="count" header="Miktar" style={{ minWidth: '6rem' }} />
                    <Column field="date" header="Tarih" style={{ minWidth: '8rem' }} filter sortable 
                            body={(rowData) => rowData.date.join('-')} />
                </DataTable>
            )}
        </div>
    );
};

export default AdminAllHelps;
