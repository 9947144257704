import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import AdminSirEdit from '../../components/admin/sir/admin-sir-edit'

const AdminSirEditPage = () => {
  return (
      <AdminTemplate>
     <AdminSirEdit/>
      </AdminTemplate>
      
  )
}

export default AdminSirEditPage