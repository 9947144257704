import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import AdminHelpsEdit from '../../components/admin/helps/admin-helps-edit'


const AdminHelpsEditPage = () => {
  return (
    <AdminTemplate>
        <AdminHelpsEdit/>
    </AdminTemplate>
  )
}

export default AdminHelpsEditPage