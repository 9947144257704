import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectFade,Autoplay } from 'swiper/modules';  // Modülleri buradan import et
import slides from "./slides.json";
import "./slider.scss";
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const Slider = () => {
  return (
    <Swiper className="slider"
    modules={[Navigation, Pagination, EffectFade,Autoplay]}
    spaceBetween={50}
      slidesPerView={1}
      effect={"fade"}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      navigation
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
        {slides.map( slide=> <SwiperSlide key={slide.id}>
            <div className="content">
                <h2>{slide.title}</h2>
                <p>{slide.desc}</p>
            </div>
            <img src={require(`../../../../assets/img/slider/${slide.image}`)} alt={slide.title}/>

        </SwiperSlide>)}
        
    </Swiper>
  )
}

export default Slider