export const settings = {
    apiURL : "https://kunyekizilay-e68cc00b8502.herokuapp.com",
    siteName: "Kizilay Kunye Takip",
    siteUrl : "kunye_kizilay.com",
    email : "",
    phone1: "168 Çağrı Merkezi",
    phone2: "+905521501868",
    address:"Ataç-1 Sokak No: 32 Yenişehir 06420 ANKARA / TÜRKİYE",
    mapUrl: "https://maps.app.goo.gl/5KkTmQa9TgBAkpCo9",
    mapEmbedUrl : "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12239.081344704267!2d32.8559941!3d39.9241555!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34e541d3bc3a9%3A0x2f8085def598965e!2zU2HEn2zEsWssIFR1bmEgQ2QuLCAwNjQzMCDDh2Fua2F5YS9BbmthcmE!5e0!3m2!1str!2str!4v1726084993863!5m2!1str!2str"
}