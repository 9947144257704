import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import AdminSirEdit from '../../components/admin/sir/admin-sir-edit'

const AdminSirPage
 = () => {
  return (
    <AdminTemplate>
       <AdminSirEdit/>
    </AdminTemplate>
    

  )
}

export default AdminSirPage
