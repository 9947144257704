import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import AdminSirB from '../../components/admin/sir/admin-sirB'

const AdminSirBPage
 = () => {
  return (
   <AdminTemplate>
     <AdminSirB/>

   </AdminTemplate>
  )
}

export default AdminSirBPage
