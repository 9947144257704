import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import validateTc from "../../../helpers/functions/validateTc";
import { updateHanedekilerById, createHanedekiler, deletePersonById, getHanedeYasayanlar } from "../../../api/sir-sevice";
import { useParams } from "react-router-dom";
import 'primeicons/primeicons.css';

const HanedeYasayanDigerKisiler = () => {
    let emptyPerson = {
        tcKimlikNo: "",
        adSoyad: "",
        yakinlikDerecesi: "",
        cinsiyet: "",
        dogumTarihi: "",
        medeniDurum: "",
        egitimDurumu: "",
        meslek: "",
        calismaDurumu: "",
        aylikGelir: "",
        hastalikTeshisiVeyaEngellilikDurumu: "",
        hastaysaDurumu: "",
        engellilikOrani: "",
        varsaKullanilanIlaclar: "",
        varsaKullanilanMalzemeler: "",
        aciklama: ""
    };

    const [persons, setPersons] = useState([]);
    const [personDialog, setPersonDialog] = useState(false);
    const [person, setPerson] = useState(emptyPerson);
    const [selectedPersons, setSelectedPersons] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const dt = useRef(null);
    const { tc } = useParams();
    const [tcValid, setTcValid] = useState(true); 
    const egitimDurumuOptions = [
        "100", "101", "102", "103", "104", "105", "106", "107", "108", "109",
        "110", "111", "112", "113", "114", "115", "116", "200", "201", "202", "203",
        "204", "205", "206", "207", "208", "209", "210", "211", "212", "213", "214", 
        "215", "216", "308", "312", "316", "314", "414", "DİĞER", ""
      ];

    const cinsiyetOptions = [
        { label: 'ERKEK', value: 'ERKEK' },
        { label: 'KADIN', value: 'KADIN' }
    ];

    const medeniDurumOptions = [
        { label: 'BEKAR', value: 'BEKAR' },
        { label: 'EVLİ', value: 'EVLİ' }
    ];

    const yakinlikDerecesiOptions = [
        { label: 'ANNE', value: 'ANNE' },
        { label: 'BABA', value: 'BABA' },
        { label: 'EVLAT', value: 'EVLAT' },
        { label: 'KARDEŞ', value: 'KARDEŞ' },
        { label: 'TORUN', value: 'TORUN' },
        { label: 'GELİN', value: 'GELİN' },
        { label: 'DAMAT', value: 'DAMAT' },
        { label: 'DİĞER', value: 'DİĞER' }
    ];

    const engellilikDurumOptions = [
        { label: 'VAR', value: 'VAR' },
        { label: 'YOK', value: 'YOK' }
    ];

    const calismaDurumOptions = [
        { label: 'ÇALIŞIYOR', value: 'ÇALIŞIYOR' },
        { label: 'ÇALIŞMIYOR', value: 'ÇALIŞMIYOR' }
    ];

    useEffect(() => {
        const fetchPersons = async () => {
            if (!tc) return;  // tc boşsa, istek yapma
            try {
                const hanedeYasayanlarResponse = await getHanedeYasayanlar(tc);
                const hanedeYasayanlar = hanedeYasayanlarResponse.data;
    
                if (hanedeYasayanlar && hanedeYasayanlar.length > 0) {
                    setPersons(hanedeYasayanlar);
                } else {
                    setPersons([]);
                    // sadece veriler yoksa mesaj göster
                    if (!hanedeYasayanlar || hanedeYasayanlar.length === 0) {
                        toast.current.show({
                            severity: "info",
                            summary: "Bilgi",
                            detail: "Hanede Yaşayan diğer kişiler bulunamadı.",
                        });
                    }
                }
            } catch (err) {
                if (err.response && err.response.data) {
                    const errorMessage = err.response.data.message || err.message;
                    toast.current.show({
                        severity: "",
                        summary: "",
                        
                    });
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: "Veri Yükleme Hatası",
                        detail: "Bir hata oluştu, lütfen tekrar deneyin.",
                    });
                }
            } finally {
                setLoading(false);
            }
        };
    
        fetchPersons();
    }, [tc]);  // tc değiştiğinde tetiklenir
    
    
    
    
    const openNew = () => {
        setPerson(emptyPerson);
        setSubmitted(false);
        setPersonDialog(true);
    };



    const hideDialog = () => {
        setSubmitted(false);
        setPersonDialog(false);
    };

    const savePerson = async () => {
        setSubmitted(true);
        // TC kimlik numarasının geçerli olup olmadığını kontrol et
        if (person.tcKimlikNo.trim() && tcValid) {
            let _persons = [...persons];
            let _person = { ...person };
    
            try {
                if (person.id) {
                    const index = findIndexById(person.id);
                    _persons[index] = _person;
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kişi Güncellendi', life: 3000 });
                    await updateHanedekilerById(_person.id, _person);
                } else {
                    await createHanedekiler(tc, _person);
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Yeni kişi oluşturuldu', life: 3000 });
                    _persons.push(_person);
                }
    
                setPersons(_persons);
                setPersonDialog(false);
                setPerson(emptyPerson);
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Kişi kaydedilemedi', life: 3000 });
            }
        } else {
            // Geçersiz TC kimlik numarası durumu
            toast.current.show({ severity: 'error', summary: 'Geçersiz TC Kimlik No', detail: 'Lütfen geçerli bir TC Kimlik No giriniz.', life: 3000 });
        }
    };
    

    const editPerson = (person) => {
        setPerson({ ...person });
        setPersonDialog(true);
    };

    const deletePerson = async () => {
        if (selectedPersons && selectedPersons.length > 0) {
            try {
                for (let person of selectedPersons) {
                    await deletePersonById(person.id);
                }
                let _persons = persons.filter((val) => !selectedPersons.some((p) => p.id === val.id));
                setPersons(_persons);
                setSelectedPersons([]);
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Seçilen kişiler silindi', life: 3000 });
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Kişiler silinemedi', life: 3000 });
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Uyarı', detail: 'Silmek için kişi seçin', life: 3000 });
        }
    };

    const findIndexById = (id) => {
        return persons.findIndex((person) => person.id === id);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _person = { ...person };
        _person[`${name}`] = val;
        setPerson(_person);

        if (name === 'tcKimlikNo') {
            setTcValid(validateTc(val));  // validateTc fonksiyonunu çağırarak geçerliliği kontrol et
        }
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
            <Button
              label="EKLE"
              icon="pi pi-plus"
              severity="success"
              onClick={openNew}
              style={{
                fontSize: '16px',  // Font boyutunu büyütün
                fontWeight: 'bold',  // Yazıyı kalın yapın
                padding: '10px 20px',  // Buton içindeki boşlukları artırın
              }}
            />
            <Button
              label="DÜZENLE"
              icon="pi pi-pencil"
              severity="warning"
              onClick={() => editPerson(selectedPersons[0])}
              disabled={!selectedPersons || selectedPersons.length !== 1}
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                padding: '10px 20px',
              }}
            />
            <Button
              label="SİL"
              icon="pi pi-trash"
              severity="danger"
              onClick={deletePerson}
              disabled={!selectedPersons || !selectedPersons.length}
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                padding: '10px 20px',
              }}
            />
          </div>
        );
    };

    const personDialogFooter = (
        <React.Fragment>
            <Button label="İPTAL" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="KAYDET" icon="pi pi-check" onClick={savePerson} />
        </React.Fragment>
    );

    return (
        <div>
            <Toast ref={toast} />
            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                <DataTable
                    ref={dt}
                    value={persons}
                    selectionMode="checkbox"
                    selection={selectedPersons}
                    onSelectionChange={(e) => setSelectedPersons(e.value)}
                    dataKey="id"
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25]}
                    globalFilter={globalFilter}
                    header=""
                    emptyMessage="No persons found."
                >
                    <Column field="tcKimlikNo" header="TC KİMLİK NO" style={{ minWidth: '8rem' }}></Column>
                    <Column field="adSoyad" header="AD SOYAD" style={{ minWidth: '10rem' }}></Column>
                    <Column field="yakinlikDerecesi" header="YAK. DER." style={{ minWidth: '8rem' }}></Column>
                    <Column field="cinsiyet" header="CİNSİYET" style={{ minWidth: '8rem' }}></Column>
                    <Column field="dogumTarihi" header="DOĞUM TARİHİ" style={{ minWidth: '8rem' }}></Column>
                    <Column field="medeniDurum" header="MEDENİ DURUMU" style={{ minWidth: '8rem' }}></Column>
                    <Column field="egitimDurumu" header="EĞİTİM DURUMU" style={{ minWidth: '8rem' }}></Column>
                    <Column field="meslek" header="MeESLEK" style={{ minWidth: '8rem' }}></Column>
                    <Column field="calismaDurumu" header="ÇALIŞMA DURUMU" style={{ minWidth: '8rem' }}></Column>
                    <Column field="aylikGelir" header="AYLIK ELİR" style={{ minWidth: '8rem' }}></Column>
                    <Column field="hastalikTeshisiVeyaEngellilikDurumu" header="HASTALIK/ENGELLİLİK DURUMU" style={{ minWidth: '8rem' }}></Column>
                    <Column field="engellilikOrani" header="ENGELLİLİK ORANI" style={{ minWidth: '8rem' }}></Column> 
                    <Column field="varsaKullanilanIlaclar" header="VARSA KULLANILAN İLAÇLAR" style={{ minWidth: '8rem' }}></Column> 
                    <Column field="varsaKullanilanMalzemeler" header="VARSA KULLANILAN MALZEMELER" style={{ minWidth: '8rem' }}></Column> 
                    <Column field="aciklama" header="AÇIKLAMA" style={{ minWidth: '8rem' }}></Column>                    
                </DataTable>
 
            </div>

            <Dialog visible={personDialog} style={{ width: '450px' }} header="Kişi Detayları" modal className="p-fluid" footer={personDialogFooter} onHide={hideDialog}>
                {/* Diğer inputlar */}
                <div className="field">
                <label htmlFor="adSoyad">Ad Soyad</label>
                <InputText
                    id="adSoyad"
                    value={person.adSoyad}
                    onChange={(e) => onInputChange(e, 'adSoyad')}
                    required
                    autoFocus
                    className={classNames({ 'p-invalid': submitted && !person.adSoyad })}
                />
                {submitted && !person.adSoyad && <small className="p-error">Ad Soyad gerekli.</small>}
            </div>
                 <div className="field">
            <label htmlFor="tcKimlikNo">TC Kimlik No</label>
            <InputText
                id="tcKimlikNo"
                value={person.tcKimlikNo}
                onChange={(e) => onInputChange(e, 'tcKimlikNo')}
                required
                className={classNames({ 'p-invalid': submitted && (!person.tcKimlikNo || !tcValid) })}
            />
            {submitted && (!person.tcKimlikNo || !tcValid) && (
                <small className="p-error">
                    {person.tcKimlikNo ? 'Geçersiz TC Kimlik No.' : 'TC Kimlik No gerekli.'}
                </small>
            )}
        </div>

        <div className="field">
    <label htmlFor="yakinlikDerecesi">YAKINLIK DER.</label>
    <Dropdown
        id="yakinlikDerecesi"
        value={person.yakinlikDerecesi}
        options={yakinlikDerecesiOptions}
        onChange={(e) => onInputChange(e, 'yakinlikDerecesi')}
        placeholder="Seçiniz"
        optionLabel="label"   // `yakinlikDerecesiOptions` listesindeki `label` değerini kullanır
        optionValue="value"   // `yakinlikDerecesiOptions` listesindeki `value` değerini kullanır
        required
        className={classNames({ 'p-invalid': submitted && !person.yakinlikDerecesi })}
    />
    {submitted && !person.yakinlikDerecesi && <small className="p-error">Yakınlık Derecesi gerekli.</small>}
</div>

                <div className="field">
            <label htmlFor="cinsiyet">CİNSİYET</label>
            <Dropdown
                id="cinsiyet"
                value={person.cinsiyet}
                options={cinsiyetOptions}
                onChange={(e) => onInputChange(e, 'cinsiyet')}
                required
                className={classNames({ 'p-invalid': submitted && !person.cinsiyet })}
            />
            {submitted && !person.cinsiyet && <small className="p-error">Cinsiyet seçilmelidir.</small>}
        </div>

                <div className="field">
                    <label htmlFor="dogumTarihi">DOĞUM TARİHİ</label>
                    <InputText id="dogumTarihi" value={person.dogumTarihi} onChange={(e) => onInputChange(e, 'dogumTarihi')} required className={classNames({ 'p-invalid': submitted && !person.dogumTarihi })} />
                    {submitted && !person.dogumTarihi && <small className="p-error"></small>}
                </div>

                <div className="field">
    <label htmlFor="medeniDurum">Medeni Durum</label>
    <Dropdown
        id="medeniDurum"
        value={person.medeniDurum}
        options={medeniDurumOptions}
        onChange={(e) => onInputChange(e, 'medeniDurum')}
        placeholder="Seçiniz"
        optionLabel="label"  // Options için label kısmı
        optionValue="value"  // Options için value kısmı
        required
        className={classNames({ 'p-invalid': submitted && !person.medeniDurum })}
    />
    {submitted && !person.medeniDurum && <small className="p-error">Medeni Durum gerekli.</small>}
</div>
<div className="field">
    <label htmlFor="calismaDurumu">Çalışma Durumu</label>
    <Dropdown
        id="calismaDurumu"
        value={person.calismaDurumu}
        options={calismaDurumOptions}
        onChange={(e) => onInputChange(e, 'calismaDurumu')}
        placeholder="Seçiniz"
        optionLabel="label"  // Options için label kısmı
        optionValue="value"  // Options için value kısmı
        required
        className={classNames({ 'p-invalid': submitted && !person.calismaDurumu })}
    />
    {submitted && !person.calismaDurumu && <small className="p-error">Çalışma Durumu gerekli.</small>}
</div>
    

                <div className="field">
            <label htmlFor="egitimDurumu">EĞİTİM DUR.</label>
            <Dropdown
                id="egitimDurumu"
                value={person.egitimDurumu}
                options={egitimDurumuOptions}
                onChange={(e) => onInputChange(e, 'egitimDurumu')}
                required
                className={classNames({ 'p-invalid': submitted && !person.egitimDurumu })}
            />
            {submitted && !person.egitimDurumu && <small className="p-error">Eğitim durumu seçilmelidir.</small>}
        </div>

                <div className="field">
                    <label htmlFor="meslek">MESLEK</label>
                    <InputText id="meslek" value={person.meslek} onChange={(e) => onInputChange(e, 'meslek')} required className={classNames({ 'p-invalid': submitted && !person.meslek })} />
                    {submitted && !person.meslek && <small className="p-error"></small>}
                </div>

                <div className="field">
                    <label htmlFor="aylikGelir">AYLIK GELİR</label>
                    <InputText id="aylikGelir" value={person.aylikGelir} onChange={(e) => onInputChange(e, 'aylikGelir')} required className={classNames({ 'p-invalid': submitted && !person.aylikGelir })} />
                    {submitted && !person.aylikGelir && <small className="p-error"></small>}
                </div>

                <div className="field">
    <label htmlFor="hastalikTeshisiVeyaEngellilikDurumu">HASTALIK TEŞ. VEYA ENG. DURUMU</label>
    <Dropdown
        id="hastalikTeshisiVeyaEngellilikDurumu"
        value={person.hastalikTeshisiVeyaEngellilikDurumu}
        options={engellilikDurumOptions}
        onChange={(e) => onInputChange(e, 'hastalikTeshisiVeyaEngellilikDurumu')}
        placeholder="Seçiniz"
        optionLabel="label"   // `engellilikDurumOptions` listesindeki `label` değerini kullanır
        optionValue="value"   // `engellilikDurumOptions` listesindeki `value` değerini kullanır
        required
        className={classNames({ 'p-invalid': submitted && !person.hastalikTeshisiVeyaEngellilikDurumu })}
    />
    {submitted && !person.hastalikTeshisiVeyaEngellilikDurumu && <small className="p-error">Bu alan gerekli.</small>}
</div>

                
                <div className="field">
                    <label htmlFor="engellilikOrani">ENGELLİLK ORANI</label>
                    <InputText id="engellilikOrani" value={person.engellilikOrani} onChange={(e) => onInputChange(e, 'engellilikOrani')} required className={classNames({ 'p-invalid': submitted && !person.engellilikOrani})} />
                    {submitted && !person.engellilikOrani && <small className="p-error"></small>}
                </div>

                <div className="field">
                    <label htmlFor="varsaKullanilanIlaclar">VARSA KULLANILAN İLAÇLAR</label>
                    <InputText id="varsaKullanilanIlaclar" value={person.varsaKullanilanIlaclar} 
                    onChange={(e) => onInputChange(e, 'varsaKullanilanIlaclar')} 
                    className={classNames({ 'p-invalid': submitted && !person.varsaKullanilanIlaclar})} />
                    {submitted && !person.varsaKullanilanIlaclar && <small className="p-error"></small>}
                </div>

                <div className="field">
                    <label htmlFor="varsaKullanilanMalzemeler">VARSA KULLANILAN MALZEMELER</label>
                    <InputText id="varsaKullanilanMalzemeler" 
                    value={person.varsaKullanilanMalzemeler} 
                    onChange={(e) => onInputChange(e, 'varsaKullanilanMalzemeler')} 
                     className={classNames({ 'p-invalid': submitted && !person.varsaKullanilanMalzemeler})} />
                    {submitted && !person.varsaKullanilanMalzemeler && <small className="p-error"></small>}
                </div>

                <div className="field">
                    <label htmlFor="aciklama">AÇIKLAMA</label>
                    <InputText id="aciklama" value={person.aciklama}
                     onChange={(e) => onInputChange(e, 'aciklama')} 
                      
                     className={classNames({ 'p-invalid': submitted && !person.aciklama})} />
                    {submitted && !person.aciklama && <small className="p-error"></small>}
                </div>
            </Dialog>
        </div>
    );
};

export default HanedeYasayanDigerKisiler


