import React, { useEffect, useState } from "react";
import { Alert, Button, ButtonGroup, Col, Form, Row, Spinner } from "react-bootstrap";
import PasswordInput from "../../common/password-input/password-input";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createSir , getSirByTc} from "../../../api/sir-sevice";
import { question, toast } from "../../../helpers/functions/swal";
import Loading from "../../common/loading/loading";
import { deleteUserById, getUserById, updateUserById} from "../../../api/user-service";
import { useNavigate, useParams } from "react-router-dom";
import validateTc from "../../../helpers/functions/validateTc";
import illist from "../../../helpers/illist.json";
import './admin-user-edit.scss'

const AdminUsersEdit = () => {
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { userId } = useParams();
  const navigate = useNavigate();
  const [ilceler, setIlceler] = useState([]);
  const [mahaleler, setMahaleler] = useState([]);

  const downloadVCard = (userData) => {
    const { ad, soyad, telefonNo, tc } = userData;
  
    // Telefon numarasını +90 ile formatla
    const formattedPhone = telefonNo.startsWith("+90") ? telefonNo : `+90${telefonNo}`;
  
    // Soyada TC ekle
    const soyadWithTc = `${soyad} ${tc}`;
  
    // VCF içeriği oluşturuluyor
    const vCardLines = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${soyadWithTc};${ad};;;`,
      `FN:${ad} ${soyad}`,
      `TEL;TYPE=HOME:${formattedPhone}`,
      "END:VCARD",
    ];
  
    // Satır başlarındaki boşlukları kaldırmak için `join("\n")` kullanıyoruz
    const vCardData = vCardLines.join("\n");
  
    const blob = new Blob([vCardData], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement("a");
    link.href = url;
    link.download = `${ad}_${soyad}.vcf`;
    link.click();
  
    URL.revokeObjectURL(url);
  };
     // Dogum tarihini dizi formatından string formatına çeviren fonksiyon
     const formatDateFromArray = (dateArray) => {
      if (!Array.isArray(dateArray) || dateArray.length !== 3) return '';
      const [year, month, day] = dateArray;
      return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`; // YYYY-MM-DD
    };

  const [initialValues, setInitialValues] = useState({
    ad: "",
    soyad: "",
    tc: "",
    sifre: "",
    telefonNo: "",
    basvuruIl: "",
    basvuruIlce: "",
    basvuruMahalle: "",
    adresNo: "",
    dogumTarihi:"",
    aciklama: "",
    roles: [],
    builtIn: false, //bazı kayıtların değiştirilmesi ve silinmesini engellemek için kullanıyoruz.
  });



  const validationSchema = Yup.object({
    ad: Yup.string().required("Lütfen adınızı giriniz..."),
    soyad: Yup.string().required("Lütfen soyadınızı giriniz..."),
    tc: Yup.string()
      .required("Lütfen TC kimlik numaranızı giriniz")
      .test("is-valid-tc", "Geçersiz TC kimlik numarası", (value) => validateTc(value)),
    telefonNo: Yup.string()
      .required("Lütfen telefon numaranızı giriniz.")
      .matches(/^(5\d{9})$/, "Telefon numarası geçerli bir formatta olmalıdır."),
    sifre: Yup.string()
      .min(8, "Şifreniz en az 8 katakterden oluşmalı."),
    basvuruIl: Yup.string().required("Lütfen bulunduğunuz ili giriniz."),
    basvuruIlce: Yup.string().required("Lütfen bulunduğunuz ilçeyi giriniz."),
    basvuruMahalle: Yup.string().required("Lütfen bulunduğunuz mahalleyi giriniz."),
    adresNo: Yup.string().required("İl yerleşim belgesinde yazılı adres numaranızı giriniz"),
    dogumTarihi: Yup.string().required("Lütfen doğum tarihinizi giriniz."),
    aciklama: Yup.string(),
    roles: Yup.array().test(
      "role_check",
      "Lütfen bir rol seçiniz",
      (val) => val.length > 0
    ),
  });


  const onSubmit = async (values) => {
    if (!values.password) {
      delete values.password;
    }
    setUpdating(true);
    try {
      await updateUserById(userId, values);
      toast("Kullanıcı başarılı bir şekilde güncellendi", "success");
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setUpdating(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = await getUserById(userId);
      const formattedDogumTarihi = formatDateFromArray(resp.data.dogumTarihi);
      setInitialValues({ ...resp.data, dogumTarihi: formattedDogumTarihi, sifre: "" })
      
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const removeUser = async () => {
    setDeleting(true);
    try {
      await deleteUserById(userId);
      toast("Kullanıcı başarılı bir şekilde silinmiştir.", "success");
      navigate(-1);
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setDeleting(false);
    }
  };


  const handleDelete = () => {
    question("Silmek istediğinize emin misiniz?", "Silme işlemi geri alınamayacaktır!").then(
      (result) => {
        if (result.isConfirmed) {
          removeUser();
        }
      }
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  

  useEffect(() => {
    if (formik.values.basvuruIl) {
      const filteredIlceler = illist
        .filter((item) => item[0] === (formik.values.basvuruIl || "")) // null kontrolü
        .map((item) => item[1]);
      setIlceler([...new Set(filteredIlceler)]);
  
      if (formik.values.basvuruIlce) {
        const filteredMahaleler = illist
          .filter((item) => item[1] === (formik.values.basvuruIlce || "")) // null kontrolü
          .map((item) => item[2]);
        setMahaleler([...new Set(filteredMahaleler)]);
      }
    }
  }, [formik.values.basvuruIl, formik.values.basvuruIlce]);
  
  const handleIlChange = (event) => {
    const selectedIl = event.target.value;
    formik.setFieldValue("basvuruIl", selectedIl);
    formik.setFieldValue("basvuruIlce", ""); // İlçe ve mahalleleri sıfırla
    formik.setFieldValue("basvuruMahalle", "");
    const filteredIlceler = illist.filter((item) => item[0] === selectedIl).map((item) => item[1]);
    setIlceler([...new Set(filteredIlceler)]); // Tekil ilçeleri al
    setMahaleler([]); // İl değişince mahalleleri sıfırla
  };

  const handleIlceChange = (event) => {
    const selectedIlce = event.target.value;
    formik.setFieldValue("basvuruIlce", selectedIlce);
    formik.setFieldValue("basvuruMahalle", ""); // Mahalleyi sıfırla
    const filteredMahaleler = illist.filter((item) => item[1] === selectedIlce).map((item) => item[2]);
    setMahaleler([...new Set(filteredMahaleler)]); // Tekil mahalleleri al
  };


  const handleButtonClick = async () => {
  try {
    const response = await getSirByTc(formik.values.tc);
    
    // Eğer sır varsa
    if (response.status === 200) {
      navigate(`/admin/sir/${formik.values.tc}`);
    }
  } catch (error) {
    // Eğer 404 hatası alırsak, yeni sir oluşturma işlemini başlat
    if (error.response && error.response.status === 404) {
      try {
        const sirData = {
          // Buraya sir nesnesinin gerekli alanlarını ekleyin
        };
        
        await createSir(sirData, formik.values.tc);
        
        // Sir oluşturulduktan sonra yönlendirme
        navigate(`/admin/sir/${formik.values.tc}`);
      } catch (createError) {
        console.error("Error occurred while creating 'sir':", createError);
        // Hata durumunda kullanıcıya bilgi verin veya hata yöneticisi uygulayın
      }
    } else {
      console.error("Error occurred while fetching 'sir':", error);
      // Diğer hataları işleyin (örneğin, sunucu hatası)
    }
  }
};



  return loading ? (
    <Loading />
  ) : (
    <Form noValidate onSubmit={formik.handleSubmit}>
      <fieldset disabled={formik.values.builtIn}>
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">

        <Form.Group className="mb-3">
        <Form.Label>TC Kimlik No</Form.Label>
        <Form.Control
          type="text"
          {...formik.getFieldProps("tc")}
          isInvalid={formik.touched.tc && formik.errors.tc}
          isValid={formik.touched.tc && !formik.errors.tc}
        />
        <Form.Control.Feedback type="invalid">{formik.errors.tc}</Form.Control.Feedback>
      </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Ad</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("ad")}
              isValid={formik.touched.ad && !formik.errors.ad}
              isInvalid={formik.touched.ad && !!formik.errors.ad}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.ad}
            </Form.Control.Feedback>
          </Form.Group>

        <Form.Group className="mb-3">
        <Form.Label>Soyad</Form.Label>
        <Form.Control
          type="text"
          {...formik.getFieldProps("soyad")}
          isInvalid={formik.touched.soyad && formik.errors.soyad}
          isValid={formik.touched.soyad && !formik.errors.soyad}
        />
        <Form.Control.Feedback type="invalid">{formik.errors.soyad}</Form.Control.Feedback>
      </Form.Group>
      
      <Form.Group as={Col} className="mb-3">
            <Form.Label>Şifre</Form.Label>
            <PasswordInput
              {...formik.getFieldProps("sifre")}
              isValid={formik.touched.sifre && !formik.errors.sifre}
              isInvalid={formik.touched.sifre && !!formik.errors.sifre}
              error={formik.errors.sifre}
            />
          </Form.Group>

          <Form.Group className="mb-3">
        <Form.Label>Doğum Tarihi</Form.Label>
        <Form.Control
          type="date"
          {...formik.getFieldProps("dogumTarihi")}
          isValid={formik.touched.dogumTarihi && !formik.errors.dogumTarihi}
          isInvalid={formik.touched.dogumTarihi && !!formik.errors.dogumTarihi}
        />
    
        <Form.Control.Feedback type="invalid">
          {formik.errors.dogumTarihi}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Telefon Numarası</Form.Label>
        <Form.Control
          type="text"
          {...formik.getFieldProps("telefonNo")}
          isInvalid={formik.touched.telefonNo && formik.errors.telefonNo}
          isValid={formik.touched.telefonNo && !formik.errors.telefonNo}
        />
        <Form.Control.Feedback type="invalid">{formik.errors.telefonNo}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Başvuru İli</Form.Label>
        <Form.Control
          as="select"
          value={formik.values.basvuruIl || ""} // Null kontrolü
          onChange={handleIlChange}
          isValid={formik.touched.basvuruIl && !formik.errors.basvuruIl}
          isInvalid={formik.touched.basvuruIl && !!formik.errors.basvuruIl}
        >

          <option value=""></option>
          {[...new Set(illist.map((item) => item[0]))].map((il) => (
            <option key={il} value={il}>
              {il}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{formik.errors.basvuruIl}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Başvuru İlçe</Form.Label>
        <Form.Control
          as="select"
          value={formik.values.basvuruIlce}
          onChange={handleIlceChange}
          isValid={formik.touched.basvuruIlce && !formik.errors.basvuruIlce}
          isInvalid={formik.touched.basvuruIlce && !!formik.errors.basvuruIlce}
        >
          <option value=""></option>
          {ilceler.map((ilce) => (
            <option key={ilce} value={ilce}>
              {ilce}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{formik.errors.basvuruIlce}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Başvuru Mahallesi</Form.Label>
        <Form.Control
          as="select"
          value={formik.values.basvuruMahalle}
          onChange={(e) => formik.setFieldValue("basvuruMahalle", e.target.value)}
          isValid={formik.touched.basvuruMahalle && !formik.errors.basvuruMahalle}
          isInvalid={formik.touched.basvuruMahalle && !!formik.errors.basvuruMahalle}
        >
          <option value=""></option>
          {mahaleler.map((mahalle) => (
            <option key={mahalle} value={mahalle}>
              {mahalle}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{formik.errors.basvuruMahalle}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Adres No</Form.Label>
        <Form.Control
          type="text"
          {...formik.getFieldProps("adresNo")}
          isInvalid={formik.touched.adresNo && formik.errors.adresNo}
          isValid={formik.touched.adresNo && !formik.errors.adresNo}
        />
        <Form.Control.Feedback type="invalid">{formik.errors.adresNo}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Açıklama</Form.Label>
        <Form.Control as="textarea" rows={3} {...formik.getFieldProps("aciklama")} />
      </Form.Group>

      <Form.Group as={Col} className="mb-3">
  <Form.Label>Roles</Form.Label>
  <Form.Check
    label="Customer"
    type="checkbox"
    name="roles"
    value="Customer"
    checked={formik.values.roles?.includes("Customer") || false}
    isValid={formik.touched.roles && !formik.errors.roles}
    isInvalid={formik.touched.roles && !!formik.errors.roles}
    disabled
  />
  <Form.Check
    label="Manager"
    type="checkbox"
    name="roles"
    value="Manager"
    checked={formik.values.roles?.includes("Manager") || false}
    isValid={formik.touched.roles && !formik.errors.roles}
    isInvalid={formik.touched.roles && !!formik.errors.roles}
    disabled
  />
  <Form.Check
    label="Coordinator"
    type="checkbox"
    name="roles"
    value="Coordinator"
    checked={formik.values.roles?.includes("Coordinator") || false}
    isValid={formik.touched.roles && !formik.errors.roles}
    isInvalid={formik.touched.roles && !!formik.errors.roles}
    disabled
  />
  <Form.Control.Feedback type="invalid">
    {formik.errors.roles}
  </Form.Control.Feedback>
</Form.Group>

        </Row>
      </fieldset>
      {formik.values.builtIn && (
        <Alert variant="warning">
          Built-in accounts cannot be deleted or updated
        </Alert>
      )}
      <div className="text-end">
        <ButtonGroup>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            İptal
          </Button>
          
          {!formik.values.builtIn && (
            <>
               <Button variant="primary" type="submit" disabled={updating}>
                {" "}
                {updating && <Spinner animation="border" size="sm" />} GÜNCELLE
              </Button>
              <Button
        variant="success"
        onClick={() => downloadVCard(formik.values)}
      >
        VCF İndir
      </Button>
              <Button
      variant="secondary"
      onClick={handleButtonClick}
    >
                {deleting && <Spinner animation="border" size="sm" />} SİR
              </Button>
              <Button
                variant="primary"
                onClick={() => navigate(`/admin/helps/${formik.values.tc}`)}
                
              >
                {deleting && <Spinner animation="border" size="sm" />} YARDIMLAR
              </Button>


              <Button
                variant="danger"
                onClick={handleDelete}
                disabled={deleting}
              >
                {deleting && <Spinner animation="border" size="sm" />} SİL
              </Button>
            </>
          )}
        </ButtonGroup>
      </div>
    </Form>
  );
};

export default AdminUsersEdit;
