import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSirByTc, updateSirById } from '../../../api/sir-sevice';
import { Container, Form, Button, Row, Col, Table, Spinner, ButtonGroup } from 'react-bootstrap';
import {  toast } from "../../../helpers/functions/swal";

const AdminSirB = () => {
  const { tc } = useParams(); // URL'den TC'yi alıyoruz
  const navigate = useNavigate();
  const [sir, setSir] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [yonlendirenKurumlar, setYonlendirenKurumlar] = useState([]);

  // Yeni eşya ihtiyaç bilgisi için state
  const [newEsya, setNewEsya] = useState({ esyaTuru: '', aciklama: '' });

  // Yeni kurum yardımı bilgisi için state
  const [newKurumYardim, setNewKurumYardim] = useState({
    kurumAdi: '',
    yardimTuru: '',
    miktar: '',
    yardimSuresi: '',
    yardimKesilmeNedeni: ''
  });

  // Yeni uygun görülen yardım türü bilgisi için state
  const [newUygunYardim, setNewUygunYardim] = useState({
    yardimTuru: '',
    ayniNakdi: '',
    yardimSekli: '',
    yardimSuresi: '',
    aciklama: ''
  });

  const [selectedKurums, setSelectedKurums] = useState([]);

  const kurumlar = [
    'SYDV',
    'BELEDİYE',
    'İŞKUR',
    'SAĞLIK KURULUŞU',
    'SOSYAL HİZMET MERKEZİ',
    'POLİS-JANDARMA',
    'TÜRK KIZILAY TOPLUM MERKEZİ',
    'AİLE ÇALIŞMA VE SOSYAL HİZ.İL MÜD.',
    'DİĞER KURUM VE KURULUŞLAR',
  ];

  useEffect(() => {
    const fetchSirData = async () => {
      setLoading(true);
      try {
        const response = await getSirByTc(tc);
        setSir(response.data);
  
        // Yonlendiren kurumlar varsa selectedKurums state'ini set et
        if (response.data.yonlendirenKurumlar) {
          setSelectedKurums(response.data.yonlendirenKurumlar);
        }
      } catch (error) {
        console.error('Sir bilgisi alınırken hata oluştu:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSirData();
  }, [tc]);

  const handleKurumSelect = (kurum) => {
    if (selectedKurums.includes(kurum)) {
      setSelectedKurums(selectedKurums.filter(k => k !== kurum));
    } else {
      setSelectedKurums([...selectedKurums, kurum]);
    }
  };

 

  const handleEsyaAdd = () => {
    setSir({ ...sir, esyaIhtiyac: [...sir.esyaIhtiyac, newEsya] });
    setNewEsya({ esyaTuru: '', aciklama: '' }); // Yeni eşyayı sıfırlıyoruz
  };

  const handleUygunYardimAdd = () => {
    setSir({ ...sir, uygunGorulenYardimTuru: [...sir.uygunGorulenYardimTuru, newUygunYardim] });
    setNewUygunYardim({
      yardimTuru: '',
      ayniNakdi: '',
      yardimSekli: '',
      yardimSuresi: '',
      aciklama: ''
    }); // Yeni uygun yardımı sıfırlıyoruz
  };


  const handleKurumYardimAdd = () => {
    setSir({ ...sir, digerKurumlarYardimDurumu: [...sir.digerKurumlarYardimDurumu, newKurumYardim] });
    setNewKurumYardim({
      kurumAdi: '',
      yardimTuru: '',
      miktar: '',
      yardimSuresi: '',
      yardimKesilmeNedeni: ''
    }); // Yeni kurum yardımını sıfırlıyoruz
  };


 
  const handleUpdate = async () => {
    setUpdating(true);
    try {
        // sir nesnesini güncelleyin
        const updatedSir = { ...sir, yonlendirenKurumlar: selectedKurums }; // Yönlendiren kurumları ekleyin
        // Sir bilgisini güncellemek için updateSirById fonksiyonunu kullanıyoruz
        console.log('Güncellenen Sir:', updatedSir);
        await updateSirById(sir.id, updatedSir); // Güncellenmiş sir nesnesini gönderin
        toast("Sir kaydı başarılı bir şekilde güncellendi", "success");
    } catch (error) {
        console.error('Sir bilgisi güncellenirken hata oluştu:', error);
    } finally {
        setUpdating(false);
    }
};

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (!sir) {
    return <p>Veri bulunamadı</p>;
  }

  return (
    <Container>

  <Row className="satirBasi">
  <h5>HANE KONUT BİLGİLERİ</h5>
</Row>     
<Container className="custom-container p-4 mb-4">
      {/* Form: Konut Bilgileri */}
      <Form>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="konutTuru">
              <Form.Label>KONUT TÜRÜ</Form.Label>
              <Form.Control
                as="select"
                value={sir.konutTuru}
                onChange={(e) => setSir({ ...sir, konutTuru: e.target.value })}
              >
                <option value="APARTMAN">Apartman</option>
                <option value="MUSTAKIL">Müstakil</option>
                <option value="GECEKONDU">Gecekondu</option>
                <option value="DIGER">Diğer</option>
              </Form.Control>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="konutKimeAit">
              <Form.Label>KONUT KİME AİT</Form.Label>
              <Form.Control
                as="select"
                value={sir.konutKimeAit}
                onChange={(e) => setSir({ ...sir, konutKimeAit: e.target.value })}
              >
                <option value="KIRACI">Kiracı</option>
                <option value="EV_SAHIBI">Ev Sahibi</option>
                <option value="KIRA_ODEMEDEN_OTURUYOR">Kira Ödemeden Oturuyor</option>
                <option value="DIGER">Diğer</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      </Container>

      <Row className="satirBasi">
  <h5>İHTİYAÇ OLDUĞU TESPİT EDİLEN EŞYALAR</h5>
</Row>    
<Container className="custom-container p-4 mb-4">
      <Table striped bordered>
        <thead>
          <tr>
            <th>EŞYA TÜRÜ</th>
            <th>AÇIKLAMA</th>
            <th>İŞLEMLER</th>
          </tr>
        </thead>
        <tbody>
          {sir.esyaIhtiyac.map((esya, index) => (
            <tr key={index}>
              <td>{esya.esyaTuru}</td>
              <td>{esya.aciklama}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => {
                    const updatedEsyaList = sir.esyaIhtiyac.filter((_, i) => i !== index);
                    setSir({ ...sir, esyaIhtiyac: updatedEsyaList });
                  }}
                >
                  Sil
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      </Container>

      <Container className="custom-container p-4 mb-4">

      {/* Yeni Eşya Ekleme */}
      <Form inline className="mb-3">
        <Form.Control
          type="text"
          placeholder="Eşya Türü"
          value={newEsya.esyaTuru}
          onChange={(e) => setNewEsya({ ...newEsya, esyaTuru: e.target.value })}
        />
        <Form.Control
          type="text"
          placeholder="Açıklama"
          value={newEsya.aciklama}
          onChange={(e) => setNewEsya({ ...newEsya, aciklama: e.target.value })}
        />
        <Button variant="primary" onClick={handleEsyaAdd}>
          Ekle
        </Button>
      </Form>
      </Container>

      <Row className="satirBasi">
  <h5>DİĞER KURUM/KURULUŞ YARDIMLARINDAN YARARLANMA DURUMU</h5>
</Row>  
<Container className="custom-container p-4 mb-4">
      

      {/* Yeni Kurum Yardımı Ekleme */}
      <Form inline className="mb-3">
        <Form.Control
          type="text"
          placeholder="Kurum Adı"
          value={newKurumYardim.kurumAdi}
          onChange={(e) => setNewKurumYardim({ ...newKurumYardim, kurumAdi: e.target.value })}
        />
        <Form.Control
          type="text"
          placeholder="Yardım Türü"
          value={newKurumYardim.yardimTuru}
          onChange={(e) => setNewKurumYardim({ ...newKurumYardim, yardimTuru: e.target.value })}
        />
        <Form.Control
          type="text"
          placeholder="Miktar"
          value={newKurumYardim.miktar}
          onChange={(e) => setNewKurumYardim({ ...newKurumYardim, miktar: e.target.value })}
        />
        <Form.Control
          type="text"
          placeholder="Yardım Süresi"
          value={newKurumYardim.yardimSuresi}
          onChange={(e) => setNewKurumYardim({ ...newKurumYardim, yardimSuresi: e.target.value })}
        />
        <Form.Control
          type="text"
          placeholder="Kesilme Nedeni"
          value={newKurumYardim.yardimKesilmeNedeni}
          onChange={(e) => setNewKurumYardim({ ...newKurumYardim, yardimKesilmeNedeni: e.target.value })}
        />
        <Button variant="primary" onClick={handleKurumYardimAdd}>
          Ekle
        </Button>
      </Form>

      </Container>  
<Container className="custom-container p-4 mb-4">
      <Table striped bordered>
        <thead>
          <tr>
            <th>Kurum Adı</th>
            <th>Yardım Türü</th>
            <th>Miktar</th>
            <th>Yardım Süresi</th>
            <th>Yardım Kesilme Nedeni</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {sir.digerKurumlarYardimDurumu.map((yardim, index) => (
            <tr key={index}>
              <td>{yardim.kurumAdi}</td>
              <td>{yardim.yardimTuru}</td>
              <td>{yardim.miktar}</td>
              <td>{yardim.yardimSuresi}</td>
              <td>{yardim.yardimKesilmeNedeni}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => {
                    const updatedYardimList = sir.digerKurumlarYardimDurumu.filter((_, i) => i !== index);
                    setSir({ ...sir, digerKurumlarYardimDurumu: updatedYardimList });
                  }}
                >
                  Sil
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </Container>

     

      <Row className="satirBasi">
  <h5>UYGUN GÖRÜLEN YARDIM BİLGİLERİ</h5>
</Row>   
 

      <Container className="custom-container p-4 mb-4">

      {/* Yeni Uygun Yardım Ekleme */}
      <Form inline className="mb-3">
        <Form.Control
          type="text"
          placeholder="Yardım Türü"
          value={newUygunYardim.yardimTuru}
          onChange={(e) => setNewUygunYardim({ ...newUygunYardim, yardimTuru: e.target.value })}
        />
        <Form.Select
  value={newUygunYardim.ayniNakdi}
  onChange={(e) => setNewUygunYardim({ ...newUygunYardim, ayniNakdi: e.target.value })}
>
  <option value="">Ayni/Nakdi</option>
  <option value="AYNİ">AYNİ</option>
  <option value="NAKDİ">NAKDİ</option>
</Form.Select>
        <Form.Control
          type="text"
          placeholder="Yardım Şekli"
          value={newUygunYardim.yardimSekli}
          onChange={(e) => setNewUygunYardim({ ...newUygunYardim, yardimSekli: e.target.value })}
        />
        <Form.Control
          type="text"
          placeholder="Yardım Süresi"
          value={newUygunYardim.yardimSuresi}
          onChange={(e) => setNewUygunYardim({ ...newUygunYardim, yardimSuresi: e.target.value })}
        />
        <Form.Control
          type="text"
          placeholder="Açıklama"
          value={newUygunYardim.aciklama}
          onChange={(e) => setNewUygunYardim({ ...newUygunYardim, aciklama: e.target.value })}
        />
        <Button variant="primary" onClick={handleUygunYardimAdd}>
          Ekle
        </Button>
      </Form>
      </Container>

      <Container className="custom-container p-4 mb-4">
      <Table striped bordered>
        <thead>
          <tr>
            <th>Yardım Türü</th>
            <th>Ayni/Nakdi</th>
            <th>Yardım Şekli</th>
            <th>Yardım Süresi</th>
            <th>Açıklama</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {sir.uygunGorulenYardimTuru.map((yardim, index) => (
            <tr key={index}>
              <td>{yardim.yardimTuru}</td>
              <td>{yardim.ayniNakdi}</td>
              <td>{yardim.yardimSekli}</td>
              <td>{yardim.yardimSuresi}</td>
              <td>{yardim.aciklama}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => {
                    const updatedYardimList = sir.uygunGorulenYardimTuru.filter((_, i) => i !== index);
                    setSir({ ...sir, uygunGorulenYardimTuru: updatedYardimList });
                  }}
                >
                  Sil
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </Container>

      <Row className="satirBasi">
  <h5>YÖNLENDİRİLEN KURUM/KURULUŞ</h5>
</Row>   
<Container className="custom-container p-4 mb-4">
      <Row>
        {kurumlar.map((kurum) => (
          <Col xs={12} md={4} key={kurum}>
            <Form.Check
              type="checkbox"
              id={`kurum-${kurum}`}
              label={kurum}
              checked={selectedKurums.includes(kurum)}
              onChange={() => handleKurumSelect(kurum)}
            />
          </Col>
        ))}
      </Row>

      <Table striped bordered hover className="mt-3">

        <tbody>
          {yonlendirenKurumlar.map((kurum, index) => (
            <tr key={index}>
              <td>{kurum.displayName}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => {
                    setYonlendirenKurumlar(yonlendirenKurumlar.filter((_, i) => i !== index));
                  }}
                >
                  Sil
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      </Container>
<ButtonGroup>
      <Button variant="primary" onClick={handleUpdate} disabled={updating}>
        {updating ? 'Güncelleniyor...' : 'GÜNCELLE'}
      </Button>
      <Button variant="secondary" onClick={() => navigate(`/admin/sir/${tc}`)}>
        SİR-A
      </Button>

      </ButtonGroup>
    </Container>
  );
};

export default AdminSirB;
