import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCell: {
        flex: 1,
        padding: 4,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
        textAlign: 'center',
        fontSize: 8, // Font boyutu 8 olarak ayarlandı
    },
    headerCell: {
        fontWeight: 'bold',
        backgroundColor: '#f2f2f2',
        fontSize: 8, // Header için de font boyutu 8 olarak ayarlandı
    },
    checkboxCell: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkbox: {
        width: 12,
        height: 12,
        borderWidth: 1,
        borderColor: '#000',
        alignSelf: 'center',
    },
    checkedCheckbox: {
        backgroundColor: '#000', // Dolu siyah kutu
    },
    uncheckedCheckbox: {
        backgroundColor: '#fff', // Beyaz içi boş kutu
    },
});

const YardimTablosu = () => {
    const yardimTurleri = [
        { adi: 'Asgari Geçim Desteği', ayni: true, nakdi: true },
        { adi: 'Gıda Yardımı', ayni: true, nakdi: true },  
        { adi: 'Doğum Yardımı', ayni: false, nakdi: false },
        { adi: 'Giyim Yardımı', ayni: true, nakdi: true },
        { adi: 'Olağan Dönem Afet/Acil Durum Yardımı', ayni: false, nakdi: false },
        { adi: 'Barınma Yardımı', ayni: false, nakdi: false },
        { adi: 'Eğitim Yardımı', ayni: false, nakdi: false },
        { adi: 'Sağlık Yardımı', ayni: false, nakdi: false },
        { adi: 'Engelli ve Tıbbi Araç/Cihaz Yardımı', ayni: false, nakdi: false },
        { adi: 'Aşevi Yardımı', ayni: false, nakdi: false },
        { adi: 'Ramazan Ayı Yardımı', ayni: true, nakdi: true },
        { adi: 'Diğer', ayni: false, nakdi: false },
    ];

    return (
        <View style={styles.table}>
            {/* Tablo Başlığı */}
            <View style={styles.tableRow}>
                <Text style={[styles.tableCell, styles.headerCell]}>Talep Edilen Yardım Türü</Text>
                <Text style={[styles.tableCell, styles.headerCell]}>Ayni</Text>
                <Text style={[styles.tableCell, styles.headerCell]}>Nakdi</Text>
            </View>
            {/* Yardım Türleri Satırları */}
            {yardimTurleri.map((yardim, index) => (
                <View key={index} style={styles.tableRow}>
                    <Text style={styles.tableCell}>{yardim.adi}</Text>
                    <View style={[styles.tableCell, styles.checkboxCell]}>
                        {/* Ayni checkbox işaretli mi? */}
                        <View
                            style={[
                                styles.checkbox,
                                yardim.ayni ? styles.checkedCheckbox : styles.uncheckedCheckbox,
                            ]}
                        />
                    </View>
                    <View style={[styles.tableCell, styles.checkboxCell]}>
                        {/* Nakdi checkbox işaretli mi? */}
                        <View
                            style={[
                                styles.checkbox,
                                yardim.nakdi ? styles.checkedCheckbox : styles.uncheckedCheckbox,
                            ]}
                        />
                    </View>
                </View>
            ))}
        </View>
    );
};

export default YardimTablosu;
