import React, { useState } from 'react';
import { downloadUsers, uploadUsers, downloadUserSample, uploadKunye,
  downloadHelpSample, uploadHelp, downloadKunyeSample } from "../../../api/excel-service";
import './admin-excel.css';
import { toast } from "../../../helpers/functions/swal";


const AdminExcel = () => {
  const [selectedUserFile, setSelectedUserFile] = useState(null);
  const [selectedKunyeFile, setSelectedKunyeFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message] = useState('');
  const [selectedHelpFile, setSelectedHelpFile] = useState(null);

  const handleDownloadUsers = async () => {
    setLoading(true);
    try {
      const response = await downloadUsers();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'users.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast("Kullanıcı dosyası başarıyla indirildi.", "success");
    } catch (error) {
      console.error("Dosya indirilirken hata:", error);
      toast("Dosya indirilirken hata oluştu.", "error");
    } finally {
      setLoading(false);
    }
  };
  
  const handleDownloadUserSample = async () => {
    setLoading(true);
    try {
      const response = await downloadUserSample();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'kullanici.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast("Kullanıcı örnek dosyası başarıyla indirildi.", "success");
    } catch (error) {
      console.error("Dosya indirilirken hata:", error);
      toast("Dosya indirilirken hata oluştu.", "error");
    } finally {
      setLoading(false);
    }
  };
  
  const handleDownloadKunye = async () => {
    setLoading(true);
    try {
      const response = await downloadKunyeSample();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'kunye.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast("Künye dosyası başarıyla indirildi.", "success");
    } catch (error) {
      console.error("Dosya indirilirken hata:", error);
      toast("Dosya indirilirken hata oluştu.", "error");
    } finally {
      setLoading(false);
    }
  };
  
  const handleUploadUsers = async () => {
    if (!selectedUserFile) {
      toast("Lütfen bir dosya seçin.", "error");
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', selectedUserFile);
      toast("Dosyanız yükleniyor,Lütfen bekleyiniz.", "success");
      const response = await uploadUsers(formData);
      console.log("Kullanıcı dosyası yüklendi", response);
      toast("Kullanıcı dosyası başarıyla yüklendi.", "success");
    } catch (error) {
      console.error("Dosya yüklenirken hata:", error);
      toast("Dosya yüklenirken hata oluştu.", "error");
    } finally {
      setLoading(false);
    }
  };
  const handleUploadKunye = async () => {
    if (!selectedKunyeFile) {
      toast("Lütfen bir dosya seçin.", "error");
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', selectedKunyeFile);
      toast("Dosyanız yükleniyor,Lütfen bekleyiniz.", "success");
      const response = await uploadKunye(formData);
      console.log("Künye dosyası yüklendi", response);
      toast("Künye yükleme işlemi başarılı bir şekilde oluşturuldu.", "success");
    } catch (err) {
      console.error("Dosya yüklenirken hata:", err);
      toast("Dosya yüklenirken hata oluştu.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadHelpSample = async () => {
    setLoading(true);
    try {
      const response = await downloadHelpSample();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "yardim.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast("Yardım örnek dosyası başarıyla indirildi.", "success");
    } catch (error) {
      console.error("Dosya indirilirken hata:", error);
      toast("Dosya indirilirken hata oluştu.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleUploadHelp = async () => {
    if (!selectedHelpFile) {
      toast("Lütfen bir dosya seçin.", "error");
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedHelpFile);
      toast("Dosyanız yükleniyor, lütfen bekleyiniz.", "success");
      const response = await uploadHelp(formData);
      console.log("Yardım dosyası yüklendi", response);
      toast("Yardım dosyası başarıyla yüklendi.", "success");
    } catch (error) {
      console.error("Dosya yüklenirken hata:", error);
      toast("Dosya yüklenirken hata oluştu.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="button-group">
      <div className="button-item">
        <button 
          style={{ backgroundColor: '#4CAF50' }} 
          onClick={handleDownloadUsers} 
          disabled={loading}>
          Bütün Kullanıcılar
        </button>
        <small>Bütün Kullanıcıları indir.</small>
      </div>

      <div className="button-item">
        <button 
          style={{ backgroundColor: '#002aff' }} 
          onClick={handleDownloadUserSample} 
          disabled={loading}>
          Örnek Kullanıcı
        </button>
        <small>Kullanıcıya ait bilgileri örnekteki gibi doldurun.</small>
      </div>

      <div className="button-item">
        <button
          style={{ backgroundColor: "#8E44AD" }}
          onClick={handleDownloadHelpSample}
          disabled={loading}
        >
          Örnek Yardım İndir
        </button>
        <small>Yardım bilgilerinin doldurulması için örnek dosyayı indir.</small>
      </div>

      <div className="button-item">
        <button 
          style={{ backgroundColor: '#FF9800' }} 
          onClick={handleDownloadKunye} 
          disabled={loading}>
          Örnek Künye
        </button>
        <small>Kullanıcıya ait yardımları örnekteki gibi doldurun.</small>
      </div>

      <div className="button-item">
        <input type="file" onChange={(e) => setSelectedUserFile(e.target.files[0])} />
        <button 
          style={{ backgroundColor: '#2196F3' }} 
          onClick={handleUploadUsers} 
          disabled={loading}>
          Kullanıcı Yükle
        </button>
        <small>Örnek exceldeki gibi oluşturduğunuz kullanıcıları yükle.</small>
      </div>

      <div className="button-item">
        <input
          type="file"
          onChange={(e) => setSelectedHelpFile(e.target.files[0])}
        />
        <button
          style={{ backgroundColor: "#E74C3C" }}
          onClick={handleUploadHelp}
          disabled={loading}
        >
          Yardım Excel Yükle
        </button>
        <small>Excel formatında yardım bilgilerini yükle.</small>
      </div>

      <div className="button-item">
        <input type="file" onChange={(e) => setSelectedKunyeFile(e.target.files[0])} />
        <button 
          style={{ backgroundColor: '#F44336' }} 
          onClick={handleUploadKunye} 
          disabled={loading}>
          Künye Yükle
        </button>
        <small>Örnek exceldeki gibi oluşturduğunuz kullanıcıya ait yardım bilgilerini yükle.</small>
      </div>

      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default AdminExcel;
