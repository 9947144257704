import React from "react";
import "./not-found.scss";

const NotFound = () => {
  return (
    <div className="not-found">
      <h2>Üzgünüm! Bir şeyler yanlış gitti.</h2>
      <p>Aradığınız sayfa bulunamıyor.</p>
    </div>
  );
};

export default NotFound;
