import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import AdminKunye from '../../components/admin/kunye/Admin-Kunye'





const AdminKunyePage = () => {
  return (

    <AdminTemplate>
   <AdminKunye/>
    </AdminTemplate>
  )
}

export default AdminKunyePage