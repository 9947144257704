import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import AdminHelps from '../../components/admin/helps/admin-helps'



const AdminHelpsPage = () => {
  return (
    <AdminTemplate>
        <AdminHelps/>
    </AdminTemplate>
  )
}

export default AdminHelpsPage