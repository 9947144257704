import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import AdminExcel from '../../components/admin/excel/admin-excel'

const AdminExcelPage = () => {
  return (
    <AdminTemplate>

        <AdminExcel/>
    </AdminTemplate>
  )
}

export default AdminExcelPage