import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './admin-search.scss'; // Stil dosyası
import DataTable from "react-data-table-component";
import { searchText } from "../../../api/user-service";
import { useNavigate } from "react-router-dom";
import _ from 'lodash'; // Lodash kütüphanesi ile debounce işlemi

const AdminSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false); 
  const navigate = useNavigate();

  // Arama işlemi fonksiyonu (aynı kalıyor)
  const handleSearch = async (query) => {
    setLoading(true);
    setSearchPerformed(true);
    try {
      const resp = await searchText(query);  // `query` ile arama yap
      console.log(resp.data);
      setUsers(resp.data);
    } catch (error) {
      console.error("Arama işlemi sırasında hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounced search fonksiyonunu useEffect ile yapıyoruz
  useEffect(() => {
    const debouncedSearch = _.debounce((query) => {
      if (query) {
        handleSearch(query);  // Eğer query varsa arama yap
      }
    }, 500);

    if (searchQuery) {
      debouncedSearch(searchQuery);  // searchQuery değiştiğinde debounced arama yap
    }

    return () => debouncedSearch.cancel();  // Temizleme işlemi
  }, [searchQuery]);  // Sadece searchQuery değiştiğinde çalışacak

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);  // searchQuery değişince arama işlemi tetiklenir
  };

  const handleClear = () => {
    setSearchQuery(""); // Arama kutusunu temizle
    setUsers([]); // Kullanıcı listesini sıfırla
    setSearchPerformed(false); 
  };

  const handleRowClick = (row) => {
    const userId = row.id; 
    console.log('Tıklanan kullanıcının ID:', userId);
    navigate(`/admin/users/${userId}`);
  };

  const columns = [
    {
      name: "TC",
      selector: (row) => row.tc,
      grow: 3,
    },
    {
      name: "Ad",
      selector: (row) => row.ad,
      grow: 2,
    },
    {
      name: "Soyad",
      selector: (row) => row.soyad,
      grow: 2,
    },
    {
      name: "Telefon",
      selector: (row) => row.telefonNo,
      grow: 2,
    },
    {
      name: "İlçe",
      selector: (row) => row.basvuruIlce,
      grow: 1,
    }
  ];

  return (
    <div className="search-container">
      <nav className="navbar navbar-light bg-light">
        <form className="form-inline">
          <input
            className="form-control search-input"
            type="search"
            placeholder="ARA"
            aria-label="Search"
            value={searchQuery}
            onChange={handleInputChange}  // Değişimlerde otomatik arama yapılır
          />
          <button type="button" className="clear-icon" onClick={handleClear}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </form>
      </nav>

      <div style={{ marginTop: '20px', width: '100%' }}>
        <DataTable
          columns={columns}
          data={users}
          progressPending={loading}
          responsive
          onRowClicked={handleRowClick} 
          noDataComponent={null}
        />
        {searchPerformed && users.length === 0 && <div className="no-data-message">Arama sonucu bulunamadı</div>}
      </div>
    </div>
  );
};

export default AdminSearch;
