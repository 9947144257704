import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.scss";
import logo from "../../../../assets/img/logo/logo.png";
import { settings } from "../../../../helpers/settings";
import {
  MdOutlineHome,
  MdInfoOutline,
  MdOutlineHeadphones,
  MdOutlinePrivacyTip,
} from "react-icons/md";
import ContactInfo from "../../contact/contact-info/contact-info";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="g-5">
          <Col lg={6} xl={3}>
            <Link to="/">
              <img src={logo} alt={settings.siteName} className="img-fluid" />
            </Link>
            <p>
              Kızılay yardımseverlerin yardımlarını, yardıma muhtaçlara ulaştıran en güvenilir kurumdur.
            </p>
          </Col>
          <Col lg={6} xl={3}>
            <h2>Hızlı Erişim</h2>
            <ul>
              <li>
                <Link to="/">
                  <MdOutlineHome /> Anasayfa
                </Link>
              </li>
              <li>
                <Link to="/about">
                  <MdInfoOutline /> Hakkımızda
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <MdOutlineHeadphones /> İletişim
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">
                  <MdOutlinePrivacyTip /> Privacy Policy
                </Link>
              </li>
            </ul>
          </Col>
          <Col lg={6} xl={3}>
            <h2>Çalışma Saatleri</h2>
            <ul>
              <li>Pazartesi-Cuma: 09:00 - 17:00</li>
              <li>Cumartesi: 09:00 - 13:00</li>
            </ul>
          </Col>
          <Col lg={6} xl={3}>
            <h2>İletişim</h2>
            <ContactInfo/>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
