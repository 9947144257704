import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import PasswordInput from "../../common/password-input/password-input";
import { updatePassword } from "../../../api/user-service";
import { toast } from "../../../helpers/functions/swal";

const PasswordForm = () => {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    eskiSifre: "",
    yeniSifre: "",
    sifreOnayi: "",
  };

  const validationSchema = Yup.object({
    eskiSifre: Yup.string().required("Lütfen geçerli şifrenizi giriniz."),
    yeniSifre: Yup.string()
    .required("Lütfen şifrenizi giriniz.")
    .min(8, "Şifreniz en az 8 katakterden oluşmalı."),
  sifreOnayi: Yup.string()
    .required("Şifrenizi tekrar giriniz.")
    .oneOf([Yup.ref("yeniSifre")], "Oluşturmak istediğiniz şifreniz eşleşmiyor."),
  });

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      await updatePassword(values);
      toast("Şifreniz güncellenmiştir.", "success");
      formik.resetForm();
    } catch (err) {
      toast(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Form noValidate onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Eski Şifreniz</Form.Label>
        <PasswordInput
          {...formik.getFieldProps("eskiSifre")}
          isInvalid={formik.touched.skiSifre && formik.errors.skiSifre}
          isValid={formik.touched.skiSifre && !formik.errors.skiSifre}
          error={formik.errors.skiSifre}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Yeni Şifreniz</Form.Label>
        <PasswordInput
          {...formik.getFieldProps("yeniSifre")}
          isInvalid={formik.touched.yeniSifre && formik.errors.yeniSifre}
          isValid={formik.touched.yeniSifre && !formik.errors.yeniSifre}
          error={formik.errors.yeniSifre}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Şifrenizi Onaylayın</Form.Label>
        <PasswordInput
          {...formik.getFieldProps("sifreOnayi")}
          isInvalid={
            formik.touched.sifreOnayi &&
            formik.errors.sifreOnayi
          }
          isValid={
            formik.touched.sifreOnayi &&
            !formik.errors.sifreOnayi
          }
          error={formik.errors.sifreOnayi}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        disabled={!(formik.dirty && formik.isValid) || loading}
      >
        {loading && <Spinner animation="border" size="sm" />} Güncelle
      </Button>
    </Form>
  );
};

export default PasswordForm;
