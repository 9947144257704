import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Form, Row, Spinner, Container } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { question, toast } from "../../../helpers/functions/swal";
import { deleteSirById, updateSirById, createSir , getSirByTc} from "../../../api/sir-sevice";
import { getUserByTc } from "../../../api/user-service";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../../assets/img/logo/logo.png";
import './admin-sir-edit.scss';
import validateTc from "../../../helpers/functions/validateTc";
import HanedeYasayanDigerKisiler from "./hanedeYasayanDigerKisiler";



const AdminSirEdit = () => {
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState(null);
  const [sir, setSir] = useState(null);
  const navigate = useNavigate();
  const { tc } = useParams();
  

  const [initialValues, setInitialValues] = useState({
    ad: "",
    soyad: "",
    tc: "",
    telefonNo: "",
    incelemeTarihi: "",
    muhatapNo: "",
    basvuruKanallari: [],
    babaAdi: "",
    anaAdi: "",
    dogumYeri: "",
    dogumTarihi: "",
    medeniDurum: "",
    cinsiyet: "",
    uyruk: "",
    meslek: "",
    calismaDurumu: "",
    calismiyorsaNedeni: "",
    aylikGelir1:"",
    egitimDurumu: "",
    email:"",
    il:"",
    ilce:"",
    mahalle:"",
    acikAdres:"",
    hastalikVeyaEngellilikDurumu:"",
    engellilikOrani:"",
    hastalikOzelDurum:"",
    veliTc:"",
    veliAdSoyad:"",
    veliTelefon:"",
    veliIl:"",
    veliIlce:"",
    veliAcikAdres:"",
    irtibatTc:"",
    irtibatAdSoyad:"",
    irtibatTelefon:"",
    irtibatIl:"",
    irtibatIlce:"",
    irtibatAcikAdres:"",
    hanedeYasayanDigerKisiler: [
      {
        tcKimlikNo: "",
        adSoyad: "",
        yakinlikDerecesi:"",
        cinsiyet:"",
        dogumTarihi: "",
        medeniDurum:"",
        egitimDurumu:"",      
        meslek:"",
        calismaDurumu: "",
        aylikGelir:"",
        hastalikTeshisiVeyaEngellilikDurumu: "",
        hastaysaDurumu:"",
        engellilikOrani: "",
        varsaKullanilanIlaclar: "",
        varsaKullanilanMalzemeler: "",
        aciklama: "",
      },
    ],
   sosyalGuvence:"",
   maasGeliri:"",
   kiraGeliri:"",
   duzensizGelir:"",
   kamuGeliri:"",
   stkGeliri:"",
   digerGelir:"",
   aylikToplamGelir:"",
   saglikGideri:"",
   kiraGideri:"",
   mutfakGideri:"",
   egitimGideri:"",
   faturaGideri:"",
   digerGiderler:"",
   aylikToplamGider:"",
   borcVar:"",
   borcMiktari:"",
   borcBankaToplam:"",
   borcBankaAylik:"",
   borcEldenToplam:"",
   borcEldenAylik:"",
   gorusenAdSoyad:"",
   gorusenGorevi:"",
   gorusenGorevYeri:"",
   gorusmeTarihi:"",
   gorusmeYeri:"",
   alinanBelgeler: [],
   yardimKarari:"",
   yardimKarariGorusleri:"",


  });

 

  const validationSchema = Yup.object({
    ad: Yup.string().required("Lütfen adınızı giriniz..."),
    soyad: Yup.string().required("Lütfen soyadınızı giriniz..."),
    tc: Yup.string(),
    telefonNo: Yup.string(),
    incelemeTarihi: Yup.string(),
    muhatapNo: Yup.string(),
    basvuruKanallari: Yup.array().of(Yup.string()).min(0, "En az bir başvuru kanalı seçmelisiniz."),
    babaAdi: Yup.string(),
    anaAdi: Yup.string(),
    dogumYeri: Yup.string(),
    dogumTarihi: Yup.string(),
    medeniDurum: Yup.string(),
    cinsiyet: Yup.string(),
    uyruk: Yup.string(),
    meslek: Yup.string(),
    calismaDurumu: Yup.string(),
    aylikGelir1: Yup.string(),
    calismiyorsaNedeni: Yup.string(),
    egitimDurumu: Yup.string().oneOf(
      ["100", "101", "102", "103", "104","105","106","107","108","109",
        "110","111","112","113","114","115","116","200","201","202","203",
        "204","205","206","207","208","209","210","211","212","213",
        "214","215","216","308","312","316","314","414","DİĞER",""
      ],
      "Eğitim durumu yüzler basamağı okuma durumu, onlar ve birler basamağı okuduğu sınıfı göstermektedir. 1-Okuyan 2-Okulu bırakmış 3-Mezun Örneğin 108 8.sınıfta okuyan 208 8.sınıftan terk 308 ortaokul mezunu demektir."
    ),
    email: Yup.string()
    .email("Geçerli bir email adresi giriniz."),
    il: Yup.string(),
    ilce: Yup.string(),
    mahalle: Yup.string(),
    acıkAdres: Yup.string(),
    hastalikVeyaEngellilikDurumu:Yup.string(),
    engellilikOrani:Yup.number()
    .typeError("Lütfen bir sayı giriniz"),
    hastalikOzelDurum:Yup.string(),
    veliTc:  Yup.string()
    .nullable() // Boş olabileceğini belirtir
    .notRequired() // Alanın zorunlu olmadığını belirtir
    .test(
      "tc-valid",
      "Geçerli bir TC kimlik numarası giriniz.",
      (value) => !value || validateTc(value)  // Eğer değer null, undefined veya boşsa geçer, değilse validateTc ile kontrol edilir
    ),
    veliAdSoyad:Yup.string(),
    veliTelefon:Yup.string(),
    veliIl:Yup.string(),
    veliIlce:Yup.string(),
    veliAcikAdres:Yup.string(),
    irtibatTc:  Yup.string()
    .nullable() // Boş olabileceğini belirtir
    .notRequired() // Alanın zorunlu olmadığını belirtir
    .test(
      "tc-valid",
      "Geçerli bir TC kimlik numarası giriniz.",
      (value) => !value || validateTc(value)  // Eğer değer null, undefined veya boşsa geçer, değilse validateTc ile kontrol edilir
    ),
    irtibatAdSoyad:Yup.string(),
    irtibatTelefon:Yup.string(),
    irtibatIl:Yup.string(),
    irtibatIlce:Yup.string(),
    irtibatAcikAdres:Yup.string(),
    hanedeYasayanDigerKisiler: Yup.array().of(
      Yup.object().shape({
        tcKimlikNo: Yup.string(),
        adSoyad: Yup.string(),
        yakinlikDerecesi: Yup.string(),
        cinsiyet: Yup.string(),
        dogumTarihi: Yup.string(),
        medeniDurum: Yup.string(),
        egitimDurumu: Yup.string(),
        meslek: Yup.string(),
        calismaDurumu: Yup.string(),
        aylikGelir: Yup.string(),
        hastalikTeshisiVeyaEngellilikDurum: Yup.string(),
        hastaysaDurumu: Yup.string(),
        engellilikOrani: Yup.string(),
        varsaKullanilanIlaclar: Yup.string(),
        varsaKullanilanMalzemeler: Yup.string(),
        aciklama: Yup.string(), 
      })),
      sosyalGuvence:Yup.string(),
      maasGeliri:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır."),
      kiraGeliri:Yup.number().positive("Gelir sıfırdan büyük olmalıdır."),
      duzensizGelir:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır."),
      kamuGeliri:Yup.number().positive("Gelir sıfırdan büyük olmalıdır."),
      stkGeliri:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır."),
      digerGelir:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır."),
      aylikToplamGelir:Yup.number()
      ,
      saglikGideri:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır.."),
      kiraGideri:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır.."),
      mutfakGideri:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır.."),
      egitimGideri:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır.."),
      faturaGideri:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır.."),
      digerGiderler:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır.."),
      aylikToplamGider:Yup.number(),
      borcVar:Yup.string(),
      borcMiktari:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır.."),
      borcBankaToplam:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır.."),
      borcBankaAylik:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır.."),
      borcEldenToplam:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır.r."),
      borcEldenAylik:Yup.number().positive("Girdiğiniz sayı sıfırdan büyük olmalıdır."),
      gorusenAdSoyad:Yup.string(),
      gorusenGorevi:Yup.string(),
      gorusenGorevYeri:Yup.string(),
      gorusmeTarihi:Yup.string(),
      gorusmeYeri:Yup.string(),
      alinanBelgeler: Yup.array().of(Yup.string()).min(0, "En az bir başvuru kanalı seçmelisiniz."),
      yardimKarari:Yup.string(),
      yardimKarariGorusleri:Yup.string(),
  });

  const formatDateToString = (dateArray) => {
    // Eğer dateArray bir dizi değilse veya uzunluğu 3'ten azsa, boş bir değer döndür
    if (!Array.isArray(dateArray) || dateArray.length < 3) {
        return ""; // Boş değer döndür
    }

    const [year, month, day] = dateArray;

    // Yıl, ay ve günün geçerliliğini kontrol et
    if (typeof year !== 'number' || typeof month !== 'number' || typeof day !== 'number') {
        return ""; // Boş değer döndür
    }

    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};

  function formatDateForInput(date) {
    const parsedDate = new Date(date);
    const offset = parsedDate.getTimezoneOffset(); // Kullanıcının yerel saat dilimi farkı
    parsedDate.setMinutes(parsedDate.getMinutes() - offset); // Tarihi yerel zamana göre düzelt
    return parsedDate.toISOString().split('T')[0]; // yyyy-MM-dd formatına çevirir
  }

  useEffect(() => {
    const loadUserAndSir = async () => {
      try {
        const [userResponse, sirResponse] = await Promise.all([getUserByTc(tc), getSirByTc(tc)]);
        if (userResponse && userResponse.data) {
          setUser(userResponse.data);
          setInitialValues(prev => ({
            ...prev,
            ad: userResponse.data.ad || "",
            soyad: userResponse.data.soyad || "",
            tc: userResponse.data.tc || "",
            telefonNo: userResponse.data.telefonNo || "",
            dogumTarihi: formatDateToString(userResponse.data.dogumTarihi) || "",
            muhatapNo: userResponse.data.adresNo || "",
            il: userResponse.data.basvuruIl || "",
            ilce: userResponse.data.basvuruIlce || "",
            mahalle: userResponse.data.basvuruMahalle || "",
          }));
        }
        console.log("Formik values updated:", formik.values.alinanBelgeler);

        if (sirResponse && sirResponse.data) {
          setSir(sirResponse.data);
          setInitialValues(prev => ({
            ...prev,
            incelemeTarihi: sirResponse.data.incelemeTarihi || "",
            basvuruKanallari: sirResponse.data.basvuruKanallari || [],
            babaAdi: sirResponse.data.babaAdi || "",
            anaAdi: sirResponse.data.anaAdi || "",
            dogumYeri: sirResponse.data.dogumYeri || "",
            medeniDurum: sirResponse.data.medeniDurum || "",
            cinsiyet: sirResponse.data.cinsiyet || "",
            uyruk: sirResponse.data.uyruk || "",
            meslek: sirResponse.data.meslek || "",
            calismaDurumu: sirResponse.data.calismaDurumu || "",
            calismiyorsaNedeni: sirResponse.data.calismiyorsaNedeni || "",
            aylikGelir1:sirResponse.data.aylikGelir1 || "",
            egitimDurumu: sirResponse.data.egitimDurumu || "",
            email: sirResponse.data.email || "",
            acikAdres: sirResponse.data.acikAdres || "",
            hastalikVeyaEngellilikDurumu: sirResponse.data.hastalikVeyaEngellilikDurumu || "",
            engellilikOrani: sirResponse.data.engellilikOrani || "",
            hastalikOzelDurum: sirResponse.data.hastalikOzelDurum || "",
            veliTc:sirResponse.data.veliTc || "",
            veliAdSoyad:sirResponse.data.veliAdSoyad || "",
            veliTelefon:sirResponse.data.veliTelefon || "",
            veliIl:sirResponse.data.veliIl || "",
            veliIlce:sirResponse.data.veliIlce || "",
            veliAcikAdres:sirResponse.data.veliAcikAdres || "",
            irtibatTc:sirResponse.data.irtibatTc || "",
            irtibatAdSoyad:sirResponse.data.irtibatAdSoyad || "",
            irtibatTelefon:sirResponse.data.irtibatTelefon || "",
            irtibatIl:sirResponse.data.irtibatIl || "",
            irtibatIlce:sirResponse.data.irtibatIlce || "",
            irtibatAcikAdres:sirResponse.data.irtibatAcikAdres || "",
            sosyalGuvence: sirResponse.data.sosyalGuvence || "",
            maasGeliri:sirResponse.data.maasGeliri || "",
            kiraGeliri:sirResponse.data.kiraGeliri || "",
            duzensizGelir:sirResponse.data.duzensizGelir || "",
            kamuGeliri:sirResponse.data.kamuGeliri || "",
            stkGeliri:sirResponse.data.stkGeliri || "",
            digerGelir:sirResponse.data.digerGelir || "",
            aylikToplamGelir:sirResponse.data.aylikToplamGelir || "",
            saglikGideri:sirResponse.data.saglikGideri || "",
            kiraGideri:sirResponse.data.kiraGideri || "",
            mutfakGideri:sirResponse.data.mutfakGideri || "",
            egitimGideri:sirResponse.data.egitimGideri || "",
            faturaGideri:sirResponse.data.faturaGideri || "",
            digerGiderler:sirResponse.data.digerGiderler || "",
            aylikToplamGider:sirResponse.data.aylikToplamGider || "",
            borcVar:sirResponse.data.borcVar || "",
            borcMiktari:sirResponse.data.borcMiktari || "",
            borcBankaToplam:sirResponse.data.borcBankaToplam || "",
            borcBankaAylik:sirResponse.data.borcBankaAylik || "",
            borcEldenToplam:sirResponse.data.borcEldenToplam || "",
            borcEldenAylik:sirResponse.data.borcEldenAylik || "",

            gorusenAdSoyad:sirResponse.data.gorusenAdSoyad || "",
            gorusenGorevi:sirResponse.data.gorusenGorevi || "",
            gorusenGorevYeri:sirResponse.data.gorusenGorevYeri || "",
            gorusmeTarihi:sirResponse.data.gorusmeTarihi || "",
            gorusmeYeri:sirResponse.data.gorusmeYeri || "",

            alinanBelgeler: sirResponse.data.alinanBelgeler || [],

            yardimKarari:sirResponse.data.yardimKarari || "",
            yardimKarariGorusleri:sirResponse.data.yardimKarariGorusleri || "",
            


          }));
        }
      } catch (err) {
        console.error("Veriler yüklenirken hata oluştu:", err);
        toast("Veri yükleme hatası: " + (err.response?.data?.message || "Bir hata oluştu."), "error");
      } finally {
        setLoading(false);
      }
    };

    loadUserAndSir();
  }, [tc]);
  

 
  const onSubmit = async (values) => {
    setSaving(true); // Kaydetme işlemi başladığında `saving` durumu
    
    try {
      const sirResponse = await getSirByTc(tc); // TC'ye göre kayıt var mı kontrolü
      if (sirResponse && sirResponse.data) {
        // Eğer TC ile bir kayıt bulunursa güncelleme işlemi
        await updateSirById(sirResponse.data.id, values);
        console.log("Formik values on submit:", values);

        toast("Sir kaydı başarılı bir şekilde güncellendi", "success");
      } else {
        // Eğer TC ile bir kayıt bulunamazsa yeni bir kayıt oluşturma
        await createSir(values, tc);
        toast("Sir kaydı başarılı bir şekilde kaydedildi", "success");
      }
    } catch (err) {
      toast(err.response?.data?.message || "Bir hata oluştu.", "error");
    } finally {
      setSaving(false); // İşlem tamamlandığında `saving` durumunu false yap
    }
  };



  const removeSir = async () => {
    setDeleting(true);
    try {
      const sirResponse = await getSirByTc(tc);
      if (sirResponse && sirResponse.data) {
        await deleteSirById(sirResponse.data.id);
        toast("Sir kaydı başarılı bir şekilde silinmiştir.", "success");
        navigate(-1);
      } else {
        toast("Silinecek bir kayıt bulunamadı.", "error");
      }
    } catch (err) {
      console.error("Silme hatası:", err);
      toast(err.response?.data?.message || "Bir hata oluştu.", "error");
    } finally {
      setDeleting(false);
    }
  };

  const handleDelete = () => {
    question("Silmek istediğinize emin misiniz?", "Silme işlemi geri alınamayacaktır!").then(
      (result) => {
        if (result.isConfirmed) {
          removeSir();
        }
      }
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });


    const maasGeliri = parseFloat(formik.values.maasGeliri) || 0;
    const kiraGeliri = parseFloat(formik.values.kiraGeliri) || 0;
    const duzensizGelir = parseFloat(formik.values.duzensizGelir) || 0;
    const kamuGeliri = parseFloat(formik.values.kamuGeliri) || 0;
    const stkGeliri = parseFloat(formik.values.stkGeliri) || 0;
    const digerGelir = parseFloat(formik.values.digerGelir) || 0;
    const saglikGideri = parseFloat(formik.values.saglikGideri) || 0;
    const kiraGideri = parseFloat(formik.values.kiraGideri) || 0;
    const egitimGideri = parseFloat(formik.values.egitimGideri) || 0;
    const faturaGideri = parseFloat(formik.values.faturaGideri) || 0;
    const mutfakGideri = parseFloat(formik.values.mutfakGideri) || 0;
    const digerGiderler = parseFloat(formik.values.digerGiderler) || 0;
  
    const toplamGelir = maasGeliri + kiraGeliri + duzensizGelir + kamuGeliri + stkGeliri + digerGelir;
    const toplamGider = saglikGideri + kiraGideri + egitimGideri + faturaGideri + mutfakGideri + digerGiderler;
  
    // Sadece değerler değiştiğinde güncelle
    if (formik.values.aylikToplamGelir !== toplamGelir) {
      formik.setFieldValue("aylikToplamGelir", toplamGelir);
    }
    if (formik.values.aylikToplamGider !== toplamGider) {
      formik.setFieldValue("aylikToplamGider", toplamGider);
    };

  
  
  if (loading) return <div>Yükleniyor...</div>; // Loading state

  return (
    <>
      {/* Bölüm başlığı ve logo */}
      <Row className="align-items-center mb-4">
        <Col xs={12} md={2}>
          <img src={logo} alt="Logo" className="img-fluid" />
        </Col>
        <Col xs={12} md={10}>
          <h1>SOSYAL İNCELEME RAPORU</h1>
        </Col>
      </Row>

      {/* Form */}
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Container className="custom-container p-4 mb-4">
          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>İNCELEME TARİHİ</Form.Label>
                <Form.Control
                  type="date"
                  {...formik.getFieldProps("incelemeTarihi")}
                  isValid={formik.touched.incelemeTarihi && !formik.errors.incelemeTarihi}
                  isInvalid={formik.touched.incelemeTarihi && !!formik.errors.incelemeTarihi}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.incelemeTarihi}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>MUHATAP NO</Form.Label>
                <Form.Control
                  type="text"
                  {...formik.getFieldProps("muhatapNo")}
                  isValid={formik.touched.muhatapNo && !formik.errors.muhatapNo}
                  isInvalid={formik.touched.muhatapNo && !!formik.errors.muhatapNo}
                  readOnly 
                />
                <Form.Control.Feedback type="invalid">{formik.errors.muhatapNo}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          
          </Row>
        </Container>
        
<Row className="satirBasi">
  <h5>BAŞVURU KANALLARI</h5>
</Row>
        <Container className="custom-container p-4 mb-4">
        <Form.Group>
   <div className="row">
    {["SOSYAL MEDYA", "DİLEKÇE BAŞVURU FORMU", "YÖNLENDİRME", "KAMU KURUM KURULUŞ", "ÇAĞRI MERKEZİ", "DİĞER"].map((kanal, index) => (
      <div className="col-md-4" key={kanal}> {/* Her satırda 3 sütun olacak şekilde 4-4-4 dağılımı */}
<Form.Check
  type="checkbox"
  label={kanal.replace("_", " ")}
  value={kanal}
  checked={Array.isArray(formik.values.basvuruKanallari) && formik.values.basvuruKanallari.includes(kanal)}  // Kontrol ekleyin
  onChange={(e) => {
    const value = e.target.value;
    formik.setFieldValue(
      "basvuruKanallari",
      formik.values.basvuruKanallari.includes(value)
        ? formik.values.basvuruKanallari.filter((k) => k !== value)
        : [...formik.values.basvuruKanallari, value]
    );
          }}
        />
      </div>
    ))}
  </div>
  {formik.touched.basvuruKanallari && formik.errors.basvuruKanallari && (
    <div className="text-danger">{formik.errors.basvuruKanallari}</div>
  )}
</Form.Group>

        </Container>

        <Row className="satirBasi">
  <h5>İHTİYAÇ SAHİBİ BİLGİLERİ</h5></Row>
  <Container className="custom-container p-4 mb-4">
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
          <Form.Group className="mb-3">
            <Form.Label>TC KİMLİK NO</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("tc")}
              isInvalid={formik.touched.tc && formik.errors.tc}
              isValid={formik.touched.tc && !formik.errors.tc}
              readOnly 
            />
            <Form.Control.Feedback type="invalid">{formik.errors.tc}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
  <Form.Label>AD</Form.Label>
  <Form.Control
    type="text"
    {...formik.getFieldProps("ad")}
    isValid={formik.touched.ad && !formik.errors.ad}
    isInvalid={formik.touched.ad && !!formik.errors.ad}
    readOnly /* Bu satır alanı kilitler */
  />
  <Form.Control.Feedback type="invalid">{formik.errors.ad}</Form.Control.Feedback>
</Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>SOYAD</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("soyad")}
              isValid={formik.touched.soyad && !formik.errors.soyad}
              isInvalid={formik.touched.soyad && !!formik.errors.soyad}
              readOnly 
            />
            <Form.Control.Feedback type="invalid">{formik.errors.soyad}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>TELEFON</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("telefonNo")}
              isValid={formik.touched.telefonNo && !formik.errors.telefonNo}
              isInvalid={formik.touched.telefonNo && !!formik.errors.telefonNo}
              readOnly 
            />
            <Form.Control.Feedback type="invalid">{formik.errors.telefonNo}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>BABA ADI</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("babaAdi")}
              isValid={formik.touched.babaAdi && !formik.errors.babaAdi}
              isInvalid={formik.touched.babaAdi && !!formik.errors.babaAdi}
              
            />
            <Form.Control.Feedback type="invalid">{formik.errors.babaAdi}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>ANNE ADI</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("anaAdi")}
              isValid={formik.touched.anaAdi && !formik.errors.anaAdi}
              isInvalid={formik.touched.anaAdi && !!formik.errors.anaAdi}
              
            />
            <Form.Control.Feedback type="invalid">{formik.errors.anaAdi}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>DOĞUM YERİ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("dogumYeri")}
              isValid={formik.touched.dogumYeri && !formik.errors.dogumYeri}
              isInvalid={formik.touched.dogumYeri && !!formik.errors.dogumYeri}
              
            />
            <Form.Control.Feedback type="invalid">{formik.errors.dogumYeri}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>DOĞUM TARİHİ</Form.Label>
            <Form.Control
    type="date"
    value={formik.values.dogumTarihi ? formatDateForInput(formik.values.dogumTarihi) : ''} // Tarihi formatlıyoruz
    onChange={(e) => formik.setFieldValue('dogumTarihi', e.target.value)} // Değeri güncelliyoruz
    isValid={formik.touched.dogumTarihi && !formik.errors.dogumTarihi}
    isInvalid={formik.touched.dogumTarihi && !!formik.errors.dogumTarihi}
    readOnly
  />
            <Form.Control.Feedback type="invalid">{formik.errors.dogumTarihi}</Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group className="mb-3">
  <Form.Label>MEDENİ DURUM</Form.Label>
  <Form.Control
    as="select" // Dropdown için 'select' kullanıyoruz
    {...formik.getFieldProps("medeniDurum")}
    isValid={formik.touched.medeniDurum && !formik.errors.medeniDurum}
    isInvalid={formik.touched.medeniDurum && !!formik.errors.medeniDurum}
  >
    <option value="">Seçiniz</option> {/* İlk değer olarak boş bir seçenek ekleyebiliriz */}
    <option value="EVLİ">EVLİ</option>
    <option value="BEKAR">BEKAR</option>
  </Form.Control>
  <Form.Control.Feedback type="invalid">{formik.errors.medeniDurum}</Form.Control.Feedback>
</Form.Group>


          <Form.Group className="mb-3">
  <Form.Label>CİNSİYET</Form.Label>
  <Form.Control
    as="select" // Dropdown için 'select' kullanıyoruz
    {...formik.getFieldProps("cinsiyet")}
    isValid={formik.touched.cinsiyet && !formik.errors.cinsiyet}
    isInvalid={formik.touched.cinsiyet && !!formik.errors.cinsiyet}
  >
    <option value="">Seçiniz</option> {/* İlk değer olarak boş bir seçenek ekleyebiliriz */}
    <option value="ERKEK">ERKEK</option>
    <option value="KADIN">KADIN</option>
  </Form.Control>
  <Form.Control.Feedback type="invalid">{formik.errors.cinsiyet}</Form.Control.Feedback>
</Form.Group>


<Form.Group className="mb-3">
  <Form.Label>UYRUK</Form.Label>
  <Form.Select
    {...formik.getFieldProps("uyruk")}
    isValid={formik.touched.uyruk && !formik.errors.uyruk}
    isInvalid={formik.touched.uyruk && !!formik.errors.uyruk}
  >
    <option value="">SEÇİNİZ</option> {/* İlk değer olarak boş bir seçenek ekledim */}
    <option value="TC">TC</option>
    <option value="SURİYE">SURİYE</option>
    <option value="IRAK">IRAK</option>
    <option value="AFGANİSTAN">AFGANİSTAN</option>
    <option value="DİĞER">DİĞER</option>
  </Form.Select>
  <Form.Control.Feedback type="invalid">{formik.errors.uyruk}</Form.Control.Feedback>
</Form.Group>

<Form.Group className="mb-3">
            <Form.Label>MESLEK/İŞ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("meslek")}
              isValid={formik.touched.meslek && !formik.errors.meslek}
              isInvalid={formik.touched.meslek && !!formik.errors.meslek}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.meslek}</Form.Control.Feedback>
          </Form.Group>


          <Form.Group className="mb-3">
          <Form.Label>ÇALIŞMA DURUMU</Form.Label>
  <Form.Control
    as="select" // Dropdown için 'select' kullanıyoruz
    {...formik.getFieldProps("calismaDurumu")}
    isValid={formik.touched.calismaDurumu && !formik.errors.calismaDurumu}
    isInvalid={formik.touched.calismaDurumu && !!formik.errors.calismaDurumu}
  >
    <option value="">Seçiniz</option> {/* İlk değer olarak boş bir seçenek ekleyebiliriz */}
    <option value="ÇALIŞIYOR">ÇALIŞIYOR</option>
    <option value="ÇALIŞMIYOR">ÇALIŞMIYOR</option>
  </Form.Control>
  <Form.Control.Feedback type="invalid">{formik.errors.calismaDurumu}</Form.Control.Feedback>
</Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>ÇALIŞMIYORSA NEDENİ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("calismiyorsaNedeni")}
              isValid={formik.touched.calismiyorsaNedeni && !formik.errors.calismiyorsaNedeni}
              isInvalid={formik.touched.calismiyorsaNedeni && !!formik.errors.calismiyorsaNedeni}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.calismiyorsaNedeni}</Form.Control.Feedback>
          </Form.Group>
     
          <Form.Group className="mb-3">
            <Form.Label>AYLIK GELİR</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("aylikGelir1")}
              isValid={formik.touched.aylikGelir1 && !formik.errors.aylikGelir1}
              isInvalid={formik.touched.aylikGelir1 && !!formik.errors.aylikGelir1}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.aylikGelir1}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>EĞİTİM DURUMU</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("egitimDurumu")}
              isValid={formik.touched.egitimDurumu && !formik.errors.egitimDurumu}
              isInvalid={formik.touched.egitimDurumu && !!formik.errors.egitimDurumu}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.egitimDurumu}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>E POSTA</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("email")}
              isValid={formik.touched.email && !formik.errors.email}
              isInvalid={formik.touched.email && !!formik.errors.email}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>İL</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("il")}
              isValid={formik.touched.il && !formik.errors.il}
              isInvalid={formik.touched.il && !!formik.errors.il}
              readOnly
            />
            <Form.Control.Feedback type="invalid">{formik.errors.il}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>İLÇE</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("ilce")}
              isValid={formik.touched.ilce && !formik.errors.ilce}
              isInvalid={formik.touched.ilce && !!formik.errors.ilce}
              readOnly
            />
            <Form.Control.Feedback type="invalid">{formik.errors.ilce}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>MAHALLE</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("mahalle")}
              isValid={formik.touched.mahalle && !formik.errors.mahalle}
              isInvalid={formik.touched.mahalle && !!formik.errors.mahalle}
              readOnly
            />
            <Form.Control.Feedback type="invalid">{formik.errors.mahalle}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>AÇIK ADRES</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("acikAdres")}
              isValid={formik.touched.acikAdres && !formik.errors.acikAdres}
              isInvalid={formik.touched.acikAdres && !!formik.errors.acikAdres}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.acikAdres}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
  <Form.Label>HAS./ENG.DURUM</Form.Label>
  <Form.Control
    as="select" // Dropdown için 'select' kullanıyoruz
    {...formik.getFieldProps("hastalikVeyaEngellilikDurumu")}
    isValid={formik.touched.hastalikVeyaEngellilikDurumu && !formik.errors.hastalikVeyaEngellilikDurumu}
    isInvalid={formik.touched.hastalikVeyaEngellilikDurumu && !!formik.errors.hastalikVeyaEngellilikDurumu}
  >
    <option value="">Seçiniz</option> {/* İlk değer olarak boş bir seçenek ekleyebiliriz */}
    <option value="VAR">VAR</option>
    <option value="YOK">YOK</option>
  </Form.Control>
  <Form.Control.Feedback type="invalid">{formik.errors.hastalikVeyaEngellilikDurumu}</Form.Control.Feedback>
</Form.Group>

<Form.Group className="mb-3">
            <Form.Label>ENG.ORANI</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("engellilikOrani")}
              isValid={formik.touched.engellilikOrani && !formik.errors.engellilikOrani}
              isInvalid={formik.touched.engellilikOrani && !!formik.errors.engellilikOrani}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.engellilikOrani}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>HAST.ÖZEL DURUM</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("hastalikOzelDurum")}
              isValid={formik.touched.hastalikOzelDurum && !formik.errors.hastalikOzelDurum}
              isInvalid={formik.touched.hastalikOzelDurum && !!formik.errors.hastalikOzelDurum}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.hastalikOzelDurum}</Form.Control.Feedback>
          </Form.Group>
        
       
        </Row>
        </Container>

        <Row className="satirBasi">
        <h5>VASİ/VELİ BİLGİLERİ</h5></Row>

        <Container className="custom-container p-4 mb-4">
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
        <Form.Group className="mb-3">
            <Form.Label>TC KİMLİK NO</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("veliTc")}
              isValid={formik.touched.veliTc && !formik.errors.veliTc}
              isInvalid={formik.touched.veliTc && !!formik.errors.veliTc}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.veliTc}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>AD SOYAD</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("veliAdSoyad")}
              isValid={formik.touched.veliAdSoyad && !formik.errors.veliAdSoyad}
              isInvalid={formik.touched.veliAdSoyad && !!formik.errors.veliAdSoyad}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.veliAdSoyad}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>TELEFON</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("veliTelefon")}
              isValid={formik.touched.veliTelefon && !formik.errors.veliTelefon}
              isInvalid={formik.touched.veliAdSoyad && !!formik.errors.veliTelefon}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.veliTelefon}</Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>İL</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("veliIl")}
              isValid={formik.touched.veliIl && !formik.errors.veliIl}
              isInvalid={formik.touched.veliIl && !!formik.errors.veliIl}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.veliIl}</Form.Control.Feedback>
          </Form.Group>
                    
          <Form.Group className="mb-3">
            <Form.Label>İLÇE</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("veliIlce")}
              isValid={formik.touched.veliIlce && !formik.errors.veliIlce}
              isInvalid={formik.touched.veliIlce && !!formik.errors.veliIlce}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.veliIlce}</Form.Control.Feedback>
          </Form.Group>
                              
          <Form.Group className="mb-3">
            <Form.Label>AÇIK ADRES</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("veliAcikAdres")}
              isValid={formik.touched.veliAcikAdres&& !formik.errors.veliAcikAdres}
              isInvalid={formik.touched.veliAcikAdres && !!formik.errors.veliAcikAdres}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.veliAcikAdres}</Form.Control.Feedback>
          </Form.Group>

        </Row>
        </Container>

        <Row className="satirBasi">
        <h5>ULAŞILAMADIĞINDA İRTİBAT KURULACAK KİŞİ BİLGİLERİ</h5></Row>

        <Container className="custom-container p-4 mb-4">
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
        <Form.Group className="mb-3">
            <Form.Label>TC KİMLİK NO</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("irtibatTc")}
              isValid={formik.touched.irtibatTc && !formik.errors.irtibatTc}
              isInvalid={formik.touched.irtibatTc && !!formik.errors.irtibatTc}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.irtibatTc}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>AD SOYAD</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("irtibatAdSoyad")}
              isValid={formik.touched.irtibatAdSoyad && !formik.errors.irtibatAdSoyad}
              isInvalid={formik.touched.irtibatAdSoyad && !!formik.errors.irtibatAdSoyad}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.irtibatAdSoyad}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>TELEFON</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("irtibatTelefon")}
              isValid={formik.touched.irtibatTelefon && !formik.errors.irtibatTelefon}
              isInvalid={formik.touched.irtibatAdSoyad && !!formik.errors.irtibatTelefon}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.irtibatTelefon}</Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>İL</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("irtibatIl")}
              isValid={formik.touched.irtibatIl && !formik.errors.irtibatIl}
              isInvalid={formik.touched.irtibatIl && !!formik.errors.irtibatIl}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.irtibatIl}</Form.Control.Feedback>
          </Form.Group>
                    
          <Form.Group className="mb-3">
            <Form.Label>İLÇE</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("irtibatIlce")}
              isValid={formik.touched.irtibatIlce && !formik.errors.irtibatIlce}
              isInvalid={formik.touched.irtibatIlce && !!formik.errors.irtibatIlce}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.irtibatIlce}</Form.Control.Feedback>
          </Form.Group>
                              
          <Form.Group className="mb-3">
            <Form.Label>AÇIK ADRES</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("irtibatAcikAdres")}
              isValid={formik.touched.irtibatAcikAdres&& !formik.errors.irtibatAcikAdres}
              isInvalid={formik.touched.irtibatAcikAdres && !!formik.errors.irtibatAcikAdres}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.irtibatAcikAdres}</Form.Control.Feedback>
          </Form.Group>

        </Row>
        </Container>

        <Row className="satirBasi">
        <h5>HANEDE YAŞAYAN DİĞER KİŞİLER</h5></Row>
        <Container className="custom-container p-4 mb-4">
       
        <HanedeYasayanDigerKisiler/>

     
        </Container>
        <Row className="satirBasi">
        <h5>SOSYAL GÜVENCE</h5></Row>
        <Container className="custom-container p-4 mb-4">
  <Row>
    {["YOK", "SGK", "BAĞKUR", "EMEKLİ SANDIĞI", "YEŞİL KART", "DİĞER"].map((secenek, index) => (
      <Col md={4} key={secenek} className="mb-3">
        <Form.Check
          type="radio"
          label={secenek.replace("_", " ")}
          value={secenek}
          name="sosyalGuvence"
          checked={formik.values.sosyalGuvence === secenek}
          onChange={(e) => {
            formik.setFieldValue("sosyalGuvence", secenek); 
            console.log("Değişen Sosyal Güvence:", formik.values.sosyalGuvence);
          }}
        />
      </Col>
    ))}
  </Row>
</Container>

        <Row className="satirBasi">
        <h5>HANE GELİR GİDER DURUMU</h5></Row>
        <Container className="custom-container p-4 mb-4">
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
        <Form.Group className="mb-3">
            <Form.Label>MAAŞ GELİRİ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("maasGeliri")}
              isValid={formik.touched.maasGeliri && !formik.errors.maasGeliri}
              isInvalid={formik.touched.maasGeliri && !!formik.errors.maasGeliri}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.maasGeliri}</Form.Control.Feedback>
          </Form.Group>


          <Form.Group className="mb-3">
            <Form.Label>KİRA GELİRİ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("kiraGeliri")}
              isValid={formik.touched.kiraGeliri && !formik.errors.kiraGeliri}
              isInvalid={formik.touched.kiraGeliri && !!formik.errors.kiraGeliri}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.kiraGeliri}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>DÜZENSİZ GELİR</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("duzensizGelir")}
              isValid={formik.touched.duzensizGelir && !formik.errors.duzensizGelir}
              isInvalid={formik.touched.duzensizGelir && !!formik.errors.duzensizGelir}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.duzensizGelir}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>KAMU GELİRİ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("kamuGeliri")}
              isValid={formik.touched.kamuGeliri && !formik.errors.kamuGeliri}
              isInvalid={formik.touched.kamuGeliri && !!formik.errors.kamuGeliri}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.kamuGeliri}</Form.Control.Feedback>
          </Form.Group>
       
          <Form.Group className="mb-3">
            <Form.Label>STK GELİR</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("stkGeliri")}
              isValid={formik.touched.stkGeliri && !formik.errors.stkGeliri}
              isInvalid={formik.touched.stkGeliri && !!formik.errors.stkGeliri}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.stkGeliri}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>DİĞER GELİR</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("digerGelir")}
              isValid={formik.touched.digerGelir && !formik.errors.digerGelir}
              isInvalid={formik.touched.stkGeliri && !!formik.errors.digerGelir}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.digerGelir}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>TOPLAM GELİR</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("aylikToplamGelir")}
              isValid={formik.touched.aylikToplamGelir && !formik.errors.aylikToplamGelir}
              isInvalid={formik.touched.aylikToplamGelir && !!formik.errors.aylikToplamGelir}
              style={{
                fontWeight: 'bold', // Kalın yazı
                border: '2px solid #ff9800', // Turuncu kenarlık
                backgroundColor: '#fff3e0', // Açık turuncu arka plan
                padding: '10px', // İç boşluk
            }}
        />
            <Form.Control.Feedback type="invalid">{formik.errors.aylikToplamGelir}</Form.Control.Feedback>
          </Form.Group>

        </Row>
        </Container>

        <Container className="custom-container p-4 mb-4">
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
        <Form.Group className="mb-3">
            <Form.Label>SAĞLIK GİDERİ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("saglikGideri")}
              isValid={formik.touched.saglikGideri && !formik.errors.saglikGideri}
              isInvalid={formik.touched.saglikGideri && !!formik.errors.saglikGideri}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.saglikGideri}</Form.Control.Feedback>
          </Form.Group>


          <Form.Group className="mb-3">
            <Form.Label>KİRA GİDERİ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("kiraGideri")}
              isValid={formik.touched.kiraGideri && !formik.errors.kiraGideri}
              isInvalid={formik.touched.kiraGideri && !!formik.errors.kiraGideri}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.kiraGideri}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>MUTFAK GİDERİ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("mutfakGideri")}
              isValid={formik.touched.mutfakGideri && !formik.errors.mutfakGideri}
              isInvalid={formik.touched.mutfakGideri && !!formik.errors.mutfakGideri}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.mutfakGideri}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>EĞİTİM GİDERİ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("egitimGideri")}
              isValid={formik.touched.egitimGideri && !formik.errors.egitimGideri}
              isInvalid={formik.touched.egitimGideri && !!formik.errors.egitimGideri}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.egitimGideri}</Form.Control.Feedback>
          </Form.Group>
       
          <Form.Group className="mb-3">
            <Form.Label>FATURA GİDERİ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("faturaGideri")}
              isValid={formik.touched.faturaGideri && !formik.errors.faturaGideri}
              isInvalid={formik.touched.faturaGideri && !!formik.errors.faturaGideri}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.faturaGideri}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>DİĞER GİDERLER</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("digerGiderler")}
              isValid={formik.touched.digerGiderler && !formik.errors.digerGiderler}
              isInvalid={formik.touched.digerGiderler && !!formik.errors.digerGiderler}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.digerGiderler}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
    <Form.Label>TOPLAM GİDERLER</Form.Label>
    <Form.Control
        type="text"
        {...formik.getFieldProps("aylikToplamGider")}
        isValid={formik.touched.aylikToplamGider && !formik.errors.aylikToplamGider}
        isInvalid={formik.touched.aylikToplamGider && !!formik.errors.aylikToplamGider}
        style={{
            fontWeight: 'bold', // Kalın yazı
            border: '2px solid #ff9800', // Turuncu kenarlık
            backgroundColor: '#fff3e0', // Açık turuncu arka plan
            padding: '10px', // İç boşluk
        }}
    />
    <Form.Control.Feedback type="invalid">{formik.errors.aylikToplamGider}</Form.Control.Feedback>
</Form.Group>

        </Row>
        </Container>

        <Container className="custom-container p-4 mb-4">
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
        <Form.Group className="mb-3">
  <Form.Label>BORÇ VAR MI?</Form.Label>
  
  {/* VAR Radyo Butonu */}
  <Form.Check 
    type="radio"
    label="VAR"
    id="borcVar"
    name="borcVar"
    value="VAR"
    {...formik.getFieldProps("borcVar")}
    isValid={formik.touched.borcVar && !formik.errors.borcVar}
    isInvalid={formik.touched.borcVar && !!formik.errors.borcVar}
  />
  
  {/* YOK Radyo Butonu */}
  <Form.Check 
    type="radio"
    label="YOK"
    id="borcYok"
    name="borcVar"
    value="YOK"
    {...formik.getFieldProps("borcVar")}
    isValid={formik.touched.borcVar && !formik.errors.borcVar}
    isInvalid={formik.touched.borcVar && !!formik.errors.borcVar}
  />
  
  {/* Hata Mesajı */}
  <Form.Control.Feedback type="invalid">{formik.errors.borcVar}</Form.Control.Feedback>
</Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>BANKA TOPLAM</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("borcBankaToplam")}
              isValid={formik.touched.borcBankaToplam && !formik.errors.borcBankaToplam}
              isInvalid={formik.touched.borcBankaToplam && !!formik.errors.borcBankaToplam}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.borcBankaToplam}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>BANKA AYLIK</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("borcBankaAylik")}
              isValid={formik.touched.borcBankaAylik && !formik.errors.borcBankaAylik}
              isInvalid={formik.touched.borcBankaAylik && !!formik.errors.borcBankaAylik}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.eborcBankaAylik}</Form.Control.Feedback>
          </Form.Group>

          
          <Form.Group className="mb-3">
            <Form.Label>BORÇ MİKTARI</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("borcMiktari")}
              isValid={formik.touched.borcMiktari && !formik.errors.borcMiktari}
              isInvalid={formik.touched.borcMiktari && !!formik.errors.borcMiktari}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.borcMiktari}</Form.Control.Feedback>
          </Form.Group>
       
          <Form.Group className="mb-3">
            <Form.Label>ELDEN BORÇ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("borcEldenToplam")}
              isValid={formik.touched.borcEldenToplam && !formik.errors.borcEldenToplam}
              isInvalid={formik.touched.borcEldenToplam && !!formik.errors.borcEldenToplam}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.borcEldenToplam}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>ELDEN AYLIK</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("borcEldenAylik")}
              isValid={formik.touched.borcEldenAylik && !formik.errors.borcEldenAylik}
              isInvalid={formik.touched.borcEldenAylik && !!formik.errors.borcEldenAylik}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.borcEldenAylik}</Form.Control.Feedback>
          </Form.Group>


        </Row>
        </Container>

        <Row className="satirBasi">
        <h5>GÖRÜŞEN BİLGİLERİ</h5></Row>

        <Container className="custom-container p-4 mb-4">
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">

        <Form.Group className="mb-3">
            <Form.Label>AD SOYAD</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("gorusenAdSoyad")}
              isValid={formik.touched.gorusenAdSoyad && !formik.errors.gorusenAdSoyad}
              isInvalid={formik.touched.gorusenAdSoyad && !!formik.errors.gorusenAdSoyad}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.gorusenAdSoyad}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>GÖREVİ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("gorusenGorevi")}
              isValid={formik.touched.gorusenGorevi && !formik.errors.gorusenGorevi}
              isInvalid={formik.touched.gorusenGorevi && !!formik.errors.gorusenGorevi}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.gorusenGorevi}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>GÖREV YERİ</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("gorusenGorevYeri")}
              isValid={formik.touched.gorusenGorevYeri && !formik.errors.gorusenGorevYeri}
              isInvalid={formik.touched.gorusenGorevYeri && !!formik.errors.gorusenGorevYeri}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.gorusenGorevYeri}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>TARİH</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("gorusmeTarihi")}
              isValid={formik.touched.gorusmeTarihi && !formik.errors.gorusmeTarihi}
              isInvalid={formik.touched.gorusmeTarihi && !!formik.errors.gorusmeTarihi}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.gorusmeTarihi}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>YER</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("gorusmeYeri")}
              isValid={formik.touched.gorusmeYeri && !formik.errors.gorusmeYeri}
              isInvalid={formik.touched.gorusmeYeri && !!formik.errors.gorusmeYeri}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.gorusmeYeri}</Form.Control.Feedback>
          </Form.Group>     

          </Row>
          </Container>

          <Row className="satirBasi">
          <h5>ALINAN BELGELER</h5></Row>
          <Container className="custom-container p-4 mb-4">
          <Form.Group>
  <div className="row">
    {[
      "DİLEKÇE BAŞVURU FORMU", "KİMLİK BELGESİ", "AÇIK RIZA FORMU", "SAĞLIK RAPORU", 
      "GELİR BELGES", "HASAR TESPİT RAPORU", "NÜFUS KAYIT ÖRNEĞİ", "KİRA KONTRATI", 
      "BORÇ BELGESİ", "ENGELLİ SAĞLIK KURULU RAPORU", "ÖĞRENCİ BELGESİ", "DOĞUM RAPORU"
    ].map((belgeler, index) => (
      <div className="col-md-3" key={belgeler}> {/* Her satırda 3 sütun olacak şekilde 4-4-4 dağılımı */}
        <Form.Check
          type="checkbox"
          label={belgeler.replace("_", " ")} // "_" yerine boşluk ekliyoruz
          value={belgeler}
          checked={Array.isArray(formik.values.alinanBelgeler) && formik.values.alinanBelgeler.includes(belgeler)}  // Seçili olup olmadığını kontrol ediyoruz
          onChange={(e) => {
            const value = e.target.value;
            formik.setFieldValue(
              "alinanBelgeler",
              formik.values.alinanBelgeler.includes(value)
                ? formik.values.alinanBelgeler.filter((k) => k !== value)  // Seçili ise çıkar
                : [...formik.values.alinanBelgeler, value]  // Seçili değilse ekle
            );
          }}
        />
      </div>
    ))}
  </div>
  
  {/* Hata mesajı */}
  {formik.touched.alinanBelgeler && formik.errors.alinanBelgeler && (
    <div className="text-danger">{formik.errors.alinanBelgeler}</div>
  )}
</Form.Group>

</Container>

<Row className="satirBasi">
        <h5>YARDIM KARARI VE GEREKÇESİ</h5></Row>

        <Container className="custom-container p-4 mb-4">
  <Row>
    <Col md={12}>
      <Form.Group className="mb-3">
        <Row>
          {/* İhtiyaç Sahibidir Checkbox */}
          <Col md={4} className="d-flex align-items-center">
            <Form.Check 
              type="radio"
              label="A"
              name="yardimKarari"
              id="A"
              value="A"
              checked={formik.values.yardimKarari === "A"}
              onChange={() => formik.setFieldValue("yardimKarari", "A")}
            />
          </Col>

          {/* İhtiyaç Sahibi Değildir Checkbox */}
          <Col md={4} className="d-flex align-items-center">
            <Form.Check 
              type="radio"
              label="B"
              name="yardimKarari"
              id="B"
              value="B"
              checked={formik.values.yardimKarari === "B"}
              onChange={() => formik.setFieldValue("yardimKarari", "B")}
            />
          </Col>
          <Col md={4} className="d-flex align-items-center">
            <Form.Check 
              type="radio"
              label="C"
              name="yardimKarari"
              id="C"
              value="C"
              checked={formik.values.yardimKarari === "C"}
              onChange={() => formik.setFieldValue("yardimKarari", "C")}
            />
          </Col>
        </Row>

        {/* Yardım Kararı Görüşleri (Geniş açıklama alanı) */}
        <Row className="mt-3">
          <Col md={12}>
            <Form.Label>YARDIM KARARI GÖRÜŞLERİ</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}  // Daha geniş bir alan
              {...formik.getFieldProps("yardimKarariGorusleri")}
              isValid={formik.touched.yardimKarariGorusleri && !formik.errors.yardimKarariGorusleri}
              isInvalid={formik.touched.yardimKarariGorusleri && !!formik.errors.yardimKarariGorusleri}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.yardimKarariGorusleri}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
    </Col>
  </Row>
</Container>


        </Form>
        <div className="text-end">
        <ButtonGroup>
  <Button variant="secondary" onClick={() => navigate(-1)}>
    İPTAL
  </Button>
  {!formik.values.builtIn && (
    <>
      <Button
        variant="primary"
        type="button"
        onClick={formik.handleSubmit}
        disabled={saving || updating}
      >
        {saving ? <Spinner animation="border" size="sm" /> : 'KAYDET'}
      </Button>

      <Button variant="secondary" 
      onClick={() => navigate(`/admin/sybf/${tc}`)}
      > SYBF
      </Button>

      <Button variant="primary" 
      onClick={() => navigate(`/admin/arf/${tc}`)}
      > ARF
      </Button>
            
            <Button
        variant="secondary"
        onClick={() => navigate(`/admin/sir/${tc}/sirB`)} // sirB rotasına yönlendir
      >
        SİR-B
      </Button>

      <Button variant="danger" onClick={handleDelete} disabled={deleting}>
        {deleting && <Spinner animation="border" size="sm" />} SİL
      </Button>


    </>
  )}
</ButtonGroup>
        </div>
     
    </>
  );
};

export default AdminSirEdit;
