import axios from "axios";
import { settings } from "../helpers/settings";
import authHeader from "../helpers/functions/auth-header";

const API_URL = settings.apiURL;

export const downloadUsers = () => {
    return axios.get(`${API_URL}/api/excel/users`, {
      headers: {
        ...authHeader(),
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob"
    });
  };


  export const downloadUserSample = () => {
    return axios.get(`${API_URL}/api/excel/download-sample-user-save`, {
      headers: {
        ...authHeader(),
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob"
    });
  };


  export const downloadKunyeSample = () => {
    return axios.get(`${API_URL}/api/excel/download-sample-kunye-save`, {
      headers: {
        ...authHeader(),
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob"
    });
  };

  export const uploadUsers = (formData) => {
    return axios.post(`${API_URL}/api/excel/upload`, formData, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      },
    });
  };

  export const downloadHelpSample = () => {
    return axios.get(`${API_URL}/api/excel/download-sample-help`, {
      headers: {
        ...authHeader(),
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    });
  };
  
  export const uploadHelp = (formData) => {
    return axios.post(`${API_URL}/api/excel/upload-help`, formData, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      },
    });
  };

  export const uploadKunye = (formData) => {
    
    return axios.post(`${API_URL}/api/excel/upload-kunye`, formData, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      },
    });
  };
  