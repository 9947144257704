import React, { useEffect, useState } from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { useNavigate, useParams } from 'react-router-dom';

import AcikRizaFormu from './admin-acik-riza-formu';
import { getUserByTc } from "../../../api/user-service";

const ARFGoruntuleVeIndir = () => {
  const navigate = useNavigate();
  const { tc } = useParams();

  const [adSoyad, setAdSoyad] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      if (!tc) return;
      setLoading(true);
      try {
        const response = await getUserByTc(tc);
        if (response.data) {
          const { ad, soyad } = response.data;
          setAdSoyad(`${ad} ${soyad}`);
        }
      } catch (error) {
        console.error("Kullanıcı verisi alınırken hata oluştu:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, [tc]);

  if (loading) return <p>Yükleniyor...</p>;

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>AÇIK RIZA FORMU</h1>

      <button onClick={() => navigate(-1)} style={{ marginBottom: '20px', padding: '10px', cursor: 'pointer' }}>
        Geri
      </button>

      <PDFViewer style={{ width: '100%', height: '600px' }}>
        <AcikRizaFormu tc={tc} adSoyad={adSoyad} />
      </PDFViewer>

      <div style={{ marginTop: '20px' }}>
        <PDFDownloadLink
          document={<AcikRizaFormu tc={tc} adSoyad={adSoyad} />}
          fileName="AcikRizaFormu.pdf"
        >
          {({ loading }) => (loading ? 'PDF Yükleniyor...' : 'PDF İndir')}
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default ARFGoruntuleVeIndir;
