import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet, Font } from '@react-pdf/renderer';
import { format } from 'date-fns';
import Roboto from "../../../assets/fonts/Roboto-Regular.ttf"; 
import logo from "../../../assets/img/logo/logo.png";
import HanedekilerTablo from './hanedekiler-tablo'; 
import YardimTablosu from './yardim-tablosu';

// Fontu embed modunda tanımlayın
Font.register({
    family: 'Roboto',
    src: Roboto,
});

const styles = StyleSheet.create({
    page: {
        padding: 10,  // Padding'i biraz azalttık
        fontSize: 9,  // Genel font boyutunu küçültüyoruz
        fontFamily: 'Roboto',
    },
    logoHeaderContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,  // Logo ve başlık arasındaki boşluğu azaltıyoruz
    },
    logo: {
        width: 50,  // Logo boyutunu küçültüyoruz
        height: 50,
        marginRight: 5,
    },
    header: {
        fontSize: 16,  // Başlık font boyutunu küçültüyoruz
        fontWeight: 'bold',
    },
    section: {
        marginTop: 10,  // Aralarındaki boşluğu azaltıyoruz
    },
    text: {
        marginBottom: 5,
        lineHeight: 1.2,  // Satır yüksekliğini azaltıyoruz
    },
    infoRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 3,  // Bilgi satırları arasındaki boşluğu azaltıyoruz
    },
    signature: {
        marginTop: 10,
        borderTop: '1px solid #000',
        width: '50%',
        textAlign: 'center',
        alignSelf: 'flex-start',
        marginLeft: 10,
    },
    tableTitle: {
        fontWeight: 'bold',
        marginBottom: 3,  // Başlık ve içerik arasındaki boşluğu azaltıyoruz
        marginTop: 10,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: 0.5,  // Tablo kenarlık kalınlığını azaltıyoruz
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCell: {
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: 0.5,  // Hücre kenarlık kalınlığını azaltıyoruz
        padding: 3,  // Hücre içi boşluğu azaltıyoruz
    },
    tableCellKey: {
        width: '25%',
        fontWeight: 'bold',
    },
    tableCellValue: {
        width: '75%',
    },
    eklerTitle: {
        fontWeight: 'bold',
        fontSize: 9,  // Ekler başlığını küçük yapıyoruz
        marginTop: 10,
    },
    eklerItem: {
        fontSize: 8,  // Ekler maddelerinin font boyutunu küçültüyoruz
        marginBottom: 2,
    }
});

const AdminSybf = ({tc, sir, hanedekiler }) => {
    const currentDate = format(new Date(), 'dd-MM-yyyy');

    
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Logo ve Başlık */}
                <View style={styles.logoHeaderContainer}>
                    <Image style={styles.logo} src={logo} />
                    <Text style={styles.header}>SOSYAL YARDIMLAŞMA FORMU</Text>
                </View>

                {/* Giriş Metni */}
                <View style={styles.section}>
                    <Text style={styles.text}>
                        Türk Kızılay tarafından uygun görülen yardımlardan faydalanmak istiyorum. 
                        Aşağıda verdiğim bilgilerimin doğruluğunu beyan eder, hakkımda gerekli inceleme 
                        ve araştırmaların yapılmasını kabul ediyorum.
                    </Text>

                    {/* Başvuru Sahibinin Bilgileri */}
                    <View style={styles.infoRow}>
                        <Text>Başvuru Sahibinin Adı Soyadı: </Text>
                        <Text>{sir.adSoyad}</Text>
                    </View>
                    <View style={styles.infoRow}>
                        <Text>Başvuru Tarihi: </Text>
                        <Text>{currentDate}</Text>
                    </View>

                    {/* İmza Alanı */}
                    <View style={styles.infoRow}>
                        <Text>İmza:</Text>
                        <View style={styles.infoRow}></View>
                    </View>

                    {/* Kimlik/İletişim Bilgileri Tablosu */}
                    <Text style={styles.tableTitle}>Başvuru Sahibinin Kimlik/İletişim Bilgileri:</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.tableCellKey]}>TC Kimlik No</Text>
                            <Text style={[styles.tableCell, styles.tableCellValue]}>{tc}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.tableCellKey]}>Adı Soyadı</Text>
                            <Text style={[styles.tableCell, styles.tableCellValue]}>{sir.adSoyad}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.tableCellKey]}>Adresi</Text>
                            <Text style={[styles.tableCell, styles.tableCellValue]}>{sir.adres}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.tableCellKey]}>Telefon</Text>
                            <Text style={[styles.tableCell, styles.tableCellValue]}>{sir.telefon}</Text>
                        </View>
                    </View>
                </View>

                {/* Hanedekiler Tablosu */}
                <Text style={styles.tableTitle}>Başvuru Sahibinin Aile Bilgileri:</Text>
                <View>
                    <HanedekilerTablo hanedekiler={hanedekiler} /> {/* HanedekilerTablo bileşenini ekledik */}
                </View>

                {/* Yardım Türleri Tablosu */}
                <Text style={styles.tableTitle}>Talep Edilen Yardım Türü:</Text>
                <View>
                    <YardimTablosu /> {/* Yeni tabloyu buraya ekledik */}
                </View>

                {/* Ekler */}
                <Text style={styles.eklerTitle}>Ekler:</Text>
                <Text style={styles.eklerItem}>1. Kimlik Belgesi Fotokopisi</Text>
                <Text style={styles.eklerItem}>2. Başvuru ve İzin Belgesi</Text>
                <Text style={styles.eklerItem}>3. Yardım Türüne Göre Gerekli Diğer Belgeler (Gelir belgesi, kira kontratı, engelli sağlık kurulu raporu)</Text>

            </Page>
        </Document>
    );
};

export default AdminSybf;
