import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import HanedeYasayanDigerKisiler from '../../components/admin/sir/hanedeYasayanDigerKisiler'

const AdminHanedeYasayanlarPage = () => {
return (
    <AdminTemplate>
        <HanedeYasayanDigerKisiler/>
    </AdminTemplate>
  )

}
export default AdminHanedeYasayanlarPage
