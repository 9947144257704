import React, {useState} from 'react';
import { Page, Text, View, Document, Image, StyleSheet,Font } from '@react-pdf/renderer';
import { format } from 'date-fns';
import logo from "../../../assets/img/logo/logo.png";
import Loading from '../../common/loading/loading';
import Roboto from "../../../assets/fonts/Roboto-Regular.ttf"; 

// Fontu embed modunda tanımlayın
Font.register({
  family: 'Roboto',
  src: Roboto,
  format: 'truetype',
});

const styles = StyleSheet.create({
  page: {
    padding: 20, // Paddingi azalt
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  header: {
    marginBottom: 15,
    textAlign: 'center',
    fontSize: 18, // Font boyutunu artırdık
    fontWeight: 'bold', // Kalın font
    marginTop: -5, // Logo ile başlık arasını daha da kısaltmak için negatif margin ekleyebiliriz
  },
  logoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center', // Logoyu ve başlığı ortalamak için justifyContent ekledik
    marginBottom: 10,
  },
  logo: {
    width: 60,
    height: 60,
    marginRight: 5, // Logo ile başlık arasındaki boşluğu ayarladık
  },
  section: {
    margin: 5, // Boşlukları azalt
    padding: 5,
  },
  text: {
    marginBottom: 5,
    lineHeight: 1.5,
  },
  checkmark: {
    marginRight: 5,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    marginTop: 12, // Boşlukları azalt
    marginBottom: 15,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    padding: 2, // Boşluğu azalt
    textAlign: 'center',
    width: '20%',
  },
  tableHeader: {
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold',
  },
  twoColumnContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8, // Boşluğu azalt
  },
  column: {
    width: '45%',
    fontSize: 8, // Küçük font
    padding: 2, // Boşluğu azalt
  },
});




  // Dinamik font boyutunu ayarlamak için fonksiyon
  const getFontSize = (text) => {
    if (!text) return 12; // Eğer metin yoksa varsayılan boyut
    const length = text.length;
    if (length < 10) return 12; // Kısa metinler için standart boyut
    if (length < 20) return 10; // Orta uzunluktaki metinler için biraz daha küçük
    return 8; // Uzun metinler için en küçük boyut
  };

const AcikRizaFormu = ({ tc, adSoyad }) => {
  const currentDate = format(new Date(), 'dd-MM-yyyy');
  const [loading, setLoading] = useState(false);

  if (loading) {
    return <p>Yükleniyor...</p>;
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
      <View style={styles.logoContainer}>
          <Image style={styles.logo} src={logo} />
          <Text style={styles.header}>AÇIK RIZA FORMU</Text>
        </View>

        {loading ? (
          <Loading /> // Eğer loading durumu true ise Loading bileşenini göster
        ) : (
          <>
        <Text style={styles.section}>TÜRKİYE KIZILAY DERNEĞİNE</Text>

        <View style={styles.section}>
          <Text style={styles.text}>
          Türkiye  Kızılay  Derneği  tarafından  gerçekleştirilen  yardım faaliyetleri  kapsamında  yardımların  tarafınıza ulaştırılabilmesi, gerektiğinde danışmanlarımızdan destek alabilmeniz ve taleplerinizin karşılanması amacıyla bir takım kişisel verilerinizin 6698 sayılı Kişisel Verilerin Korunması Kanunu m.5/1 ve m.5/2’de yer alan (a), (ç) ve (f) hükümleri çerçevesinde fiziksel ve elektronik ortamda işlenmektedir. Kişisel verilerinizin işlenmesi ile ilgili  Kanun’un  11.  Maddesinde  yer  alan  bilgi  edinme  hakları  ve  detaylı  bilgilere  https://www.kizilay.org.tr adresinde yer alan Kişisel Verilerin Korunması Politikasından ulaşabilirsiniz. 

          </Text>
          
          <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
  <Text style={styles.checkmark}>[√]</Text>
  <Text>Kişisel Verilerin İşlenmesine İlişkin Kızılay Aydınlatma Metnini okudum anladım.</Text>
</View>

          <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
          <Text style={styles.checkmark}>[√]</Text>
            <Text>6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca, Kızılay ile paylaşmış özel nitelikli kişisel verilerimizin Kızılay tarafından işlemesine onay veriyorum.</Text>
          </View>

          <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
          <Text style={styles.checkmark}>[√]</Text>
            <Text>6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca, Kızılay’ın, kampanyalar ve diğer haberler ile ilgili tarafıma elektronik posta, telefon ve benzeri iletişim kanalları vasıtasıyla ses ve görüntü içerikli bilgilendirme iletileri gönderilmesine ve kişisel verilerimin bu amaçla işlenmesine onay veriyorum.</Text>
          </View>

          <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
          <Text style={styles.checkmark}>[√]</Text>
            <Text>Kurumunuza; sosyal yardım için başvurmuş olup kişisel bilgilerimin ve muhtaçlığımın/ gelir durumumun tespiti için aşağıda maddeler halinde sayılan sorgulamaların yapılmasına; elde edilen bilgilerin başvurumun değerlendirilmesi sürecinde kullanılmasına ve 13 Eylül 2014 sayılı resmi gazetede yayınlanan Sosyal Yardım Verilerinin Kaydedilmesine ve Paylaşılmasına İlişkin Yönetmelik kapsamında verilerimin kaydedilmesine ve paylaşılmasına;</Text>
          </View>

          {/* Tablonun oluşturulması */}
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={styles.tableCell}>TC KİMLİK NO</Text>
              <Text style={styles.tableCell}>AD SOYAD</Text>
              <Text style={styles.tableCell}>İZİN VERİYORUM (İMZA)</Text>
              <Text style={styles.tableCell}>TARİH</Text>
              <Text style={styles.tableCell}>BAŞVURANA{"\n"}YAKINLIĞI</Text>

            </View>
            <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { fontSize: getFontSize(tc) }]}>{tc}</Text>
            <Text style={[styles.tableCell, { fontSize: getFontSize(adSoyad) }]}>{adSoyad}</Text>
              <Text style={[styles.tableCell, { fontSize: getFontSize("") }]}> {/* İmza hücresi boş olduğu için font boyutu 12 olarak ayarlanabilir */}</Text>
              <Text style={[styles.tableCell, { fontSize: getFontSize(currentDate) }]}>{currentDate}</Text>
              <Text style={[styles.tableCell, { fontSize: getFontSize("") }]}> {/* Yakınlık hücresi boş olduğu için font boyutu 12 olarak ayarlanabilir */}</Text>
            </View>
          </View>

          {/* İki sütun ekleme */}
<View style={styles.twoColumnContainer}>
  <View style={styles.column}>
    <Text>Sorgulama Yapılan Kurumlar</Text>
    <Text>1) İçişleri Bakanlığı Nüfus ve Vatandaşlık İşleri Genel Müdürlüğü</Text>
    <Text style={{ paddingLeft: 10 }}>   1. Nüfus Aile Kayıt Örneği</Text>
    <Text style={{ paddingLeft: 10 }}>   2. Aile Kayıt Örneği</Text>
    <Text style={{ paddingLeft: 10 }}>   3. Kişi Kayıt Örneği</Text>
    <Text style={{ paddingLeft: 10 }}>   4. Olay Bilgileri</Text>
    <Text style={{ paddingLeft: 10 }}>   5. Adres Bilgileri</Text>
    
    <Text>2) İŞKUR Genel Müdürlüğü</Text>
    <Text style={{ paddingLeft: 10 }}>   6. İŞKUR Kaydı</Text>
    <Text style={{ paddingLeft: 10 }}>   7. İşsizlik Sigortası Ödeneği</Text>
    <Text style={{ paddingLeft: 10 }}>   8. Kısa Çalışma Ödeneği</Text>
    <Text style={{ paddingLeft: 10 }}>   9. İş Kaybı Tazminatı</Text>
    <Text style={{ paddingLeft: 10 }}>   10. İstihdama Yönelik Faaliyet Sonucu</Text>
    
    <Text>3) Çocuk Hizmetleri Genel Müdürlüğü</Text>
    <Text style={{ paddingLeft: 10 }}>   11. Ayni/Nakdi Yardım</Text>
    
    <Text>4) Vakıflar Genel Müdürlüğü</Text>
    <Text style={{ paddingLeft: 10 }}>   12. Muhtaç Aylığı</Text>
    <Text style={{ paddingLeft: 10 }}>   13. Kuru Gıda Yardımı</Text>
    <Text style={{ paddingLeft: 10 }}>   14. Burs Hizmetleri</Text>
    
    <Text>5) Gelir İdaresi Başkanlığı</Text>
    <Text style={{ paddingLeft: 10 }}>   15. Vergi Mükellefiyeti</Text>
    <Text style={{ paddingLeft: 10 }}>   16. Araç Sahipliği</Text>
    
    <Text>6) Tapu ve Kadastro Genel Müdürlüğü</Text>
    <Text style={{ paddingLeft: 10 }}>   17. Gayrimenkul Sahipliği</Text>
    
    <Text>7) Engelli ve Yaşlı Hizmetleri Genel Müdürlüğü</Text>
    <Text style={{ paddingLeft: 10 }}>   18. Evde Bakım Aylığı</Text>
    
    <Text>8) Sosyal Yardımlar Genel Müdürlüğü</Text>
    <Text style={{ paddingLeft: 10 }}>   19. Ayni/Nakdi Yardım ve 2022 Sayılı Kanun Kapsamında Alınan Aylık Bilgileri</Text>
    <Text style={{ paddingLeft: 10 }}>   20. Proje Bilgileri</Text>
    <Text style={{ paddingLeft: 10 }}>   21. Gelir Testi Sonucu</Text>
    <Text style={{ paddingLeft: 10 }}>   22. Doğum Yardımı Bilgileri</Text>
    
    <Text>9) Tarım ve Orman Bakanlığı</Text>
    <Text style={{ paddingLeft: 10 }}>   23. Çiftçi Kayıt Sistemi Sorgulamaları</Text>
    <Text style={{ paddingLeft: 10 }}>   24. Hayvan Bilgisi</Text>
  </View>
  
  {/* İkinci Sütun */}
  <View style={styles.column}>
  <Text>10) Sosyal Güvenlik Kurumu Başkanlığı</Text>
  <Text style={{ paddingLeft: 10 }}>   25. Sosyal Güvenlik Bilgileri</Text>
  <Text style={{ paddingLeft: 10 }}>   26. Sağlık Müstehaklık Bilgileri</Text>
  
  <Text>11) Sağlık Bakanlığı</Text>
  <Text style={{ paddingLeft: 10 }}>   27. Aile Hekimliği Bilgi Sistemi Sağlık Kontrol Bilgisi</Text>
  <Text style={{ paddingLeft: 10 }}>   28. E-Erişkinler İçin Engellilik Sağlık Kurulu Raporu</Text>
  <Text style={{ paddingLeft: 10 }}>   29. Çocuklar İçin Özel Gereksinim Raporu (ÇÖZGER)</Text>
  <Text style={{ paddingLeft: 10 }}>   30. Ulusal Engelliler Veri Bankası Uygulaması</Text>
  
  <Text>12) Kredi ve Yurtlar Genel Müdürlüğü</Text>
  <Text style={{ paddingLeft: 10 }}>   31. Burs ve Kredi Bilgisi</Text>
  
  <Text>13) İçişleri Bakanlığı İller İdaresi Genel Müdürlüğü</Text>
  <Text style={{ paddingLeft: 10 }}>   32. Terör Kaybı Tazminatı</Text>
  
  <Text>14) İçişleri Bakanlığı Bilgi İşlem Dairesi Başkanlığı</Text>
  <Text style={{ paddingLeft: 10 }}>   33. Geçici Köy Korucusu Maaş Bilgileri</Text>
  <Text style={{ paddingLeft: 10 }}>   34. E-Belediye Sistemi Yardım Bilgileri</Text>
  
  <Text>15) İçişleri Bakanlığı Göç İdaresi Genel Müdürlüğü</Text>
  <Text style={{ paddingLeft: 10 }}>   35. GöçNet Sisteminde Kayıtlı Bilgiler</Text>
  
  <Text>16) Milli Eğitim Bakanlığı</Text>
  <Text style={{ paddingLeft: 10 }}>   36. Okul Devam Bilgisi</Text>
  <Text style={{ paddingLeft: 10 }}>   37. Okul Başarı Bilgisi</Text>
  <Text style={{ paddingLeft: 10 }}>   38. Burs Bilgisi</Text>
  
  <Text>17) Milli Savunma Bakanlığı</Text>
  <Text style={{ paddingLeft: 10 }}>   39. Askerlik Durum Bilgisi</Text>
  <Text style={{ paddingLeft: 10 }}>   40. Özürlü Vatandaşların Askerlik Bilgisi</Text>
  
  <Text>18) Adalet Bakanlığı</Text>
  <Text style={{ paddingLeft: 10 }}>   41. UYAP Nafaka Bilgisi</Text>
  
  <Text>19) Bankalar</Text>
  <Text style={{ paddingLeft: 10 }}>   42. Kayıtlı Mevduat ve Harcama Bilgileri</Text>
  
  <Text>20) Türk Kızılay</Text>
  <Text style={{ paddingLeft: 10 }}>   43. Yardım Bilgileri</Text>
  
  <Text>21) Hazine ve Maliye Bakanlığı</Text>
  <Text style={{ paddingLeft: 10 }}>   44. Maaş Ödeme Bilgileri</Text>


</View>
</View>

<View style={{ alignItems: 'center', marginTop: 20 }}>
  <Text style={{ fontSize: 6, textAlign: 'center' }}>
    (Form No: FRM.006; İlk Yür. Tar.: 28.10.2019; Rev. No: 01; Rev. Tarihi: 28.12.2020; Sahibi: Sosyal Hizmetler Direktörlüğü)
  </Text>
</View>
</View>
</>
          )}
          
      </Page>
    </Document>
  );
};


export default AcikRizaFormu;
