import React from 'react'
import AdminTemplate from '../../templates/admin-template'
import AdminAllHelps from '../../components/admin/helps/admin-allHelps'

const AdminAllHelpsPage = () => {
  return (
    <AdminTemplate>
    <AdminAllHelps/>

    </AdminTemplate>
  )
}

export default AdminAllHelpsPage