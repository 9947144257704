import React, { useEffect, useState } from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import { getTotalHelps } from '../../../api/helps-service'; // API'den toplam verileri çekmek için fonksiyonu import ediyoruz.
import './help-summary.scss'

const SummaryBox = ({ tc, updateCount }) => {
  const [totals, setTotals] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTotals = async () => {
      setLoading(true); // Yükleniyor durumunu başlatıyoruz
      setError(null); // Önceki hata mesajını sıfırlıyoruz
      try {
        const response = await getTotalHelps(tc);
        setTotals(response.data.totals);
      } catch (err) {
        setError("Toplam yardım verileri yüklenirken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchTotals();
  }, [tc, updateCount]); // tc ve updateCount değiştiğinde verileri yeniden yükle

  if (loading) return <p>Yükleniyor...</p>;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div className="summary-box">
      <h3>TOPLAM YARDIMLAR</h3>
      <Row>
        {totals && Object.entries(totals).map(([key, value]) => (
          <Col xs={12} sm={6} md={4} key={key} className="mb-3"> {/* 3 sütunlu düzen için */}
            <div className="total-item">
              {key === 'HASTA VEYA ÇOCUK BEZİ' ? 'BEZ(HAS.-BBK)' : key}: {value}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default SummaryBox;
