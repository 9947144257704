import axios from "axios";
import { settings } from "../helpers/settings";
import authHeader from "../helpers/functions/auth-header";

const API_URL = settings.apiURL;

export const getHelpsByUserTc = (tc) => {
    return axios.get(`${API_URL}/api/helps/?tc=${tc}`, { headers: authHeader() });
};

export const updateHelpById = (id, help) => {
    return axios.put(`${API_URL}/api/helps/${id}`, help, { headers: authHeader() });
  };

export const addHelp = (help) => {
    return axios.post(`${API_URL}/api/helps`, help, { headers: authHeader() });
  };
  
export const getTotalHelps = (tc) => {
    return axios.get(`${API_URL}/api/helps/totals/${tc}`, { headers: authHeader() });
};

export const deleteHelp = (id) => {
    return axios.delete(`${API_URL}/api/helps/${id}`, { headers: authHeader() });
  };

export const getAllHelps = (startDate,endDate) => {
    return axios.get(`${API_URL}/api/helps/date-range?startDate=${startDate}&endDate=${endDate}`, { headers: authHeader() });
  };