import React from 'react'
import UserTemplate from '../../templates/user-template'
import WhatWeDo from '../../components/user/about/what-we-do/what-we-do'
import PageHeader from '../../components/user/common/page-header/page-header'
import Spacer from '../../components/common/spacer/spacer'
import WhoWeAre from '../../components/user/about/who-we-are/who-we-are'

const AboutPage = () => {
  return (
    <UserTemplate>
     <PageHeader title="Hakkımızda"/>
     <Spacer/>
      <WhatWeDo/>
      <Spacer/>
      <WhoWeAre/>
    </UserTemplate>
  )
}

export default AboutPage