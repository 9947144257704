import React from 'react'
import CustomRoutes from './router/custom-routes'



const App = () => {
  return (
    <div>
      <CustomRoutes/>
    </div>
  )
}

export default App