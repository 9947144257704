import React, { useEffect, useState, useCallback } from "react";
import { Button, Spinner, ButtonGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { downloadUsers, getUsersByPage, searchText } from "../../../api/user-service"; 
import fileDownload from "js-file-download";
import { FaFileDownload } from 'react-icons/fa';

const columns = [
  {
    name: "TC",
    selector: (row) => row.tc,
    sortable: true,
  },
  {
    name: "Ad",
    selector: (row) => row.ad,
    sortable: true,
  },
  {
    name: "Soyad",
    selector: (row) => row.soyad,
    sortable: true,
  },
  {
    name: "Telefon",
    selector: (row) => row.telefonNo,
    sortable: true,
  },
  {
    name: "İlçe",
    selector: (row) => row.basvuruIlce,
    sortable: true,
  },
  {
    name: "İl",
    selector: (row) => row.basvuruIl,
    sortable: true,
  },
];

const AdminUsers = () => {
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = useState(''); 
  const navigate = useNavigate();

  const loadFilteredData = async (searchQuery) => {
    try {
      const resp = await searchText(searchQuery);
      setUsers(resp.data); 
      setTotalRows(resp.data.length); 
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const loadData = useCallback(async (page) => {
    try {
      const resp = await getUsersByPage(page, perPage);
      const { content, totalElements } = resp.data;
      setUsers(content);
      setTotalRows(totalElements);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [perPage]);

  const handleChangePage = (page) => {
    if (filterText) {
      loadFilteredData(filterText, page - 1); 
    } else {
      loadData(page - 1);
    }
  };

  const handleChangeRowsPerPage = async (newPerPage, page) => {
    setPerPage(newPerPage);
    handleChangePage(page);
  };

  const handleRowClicked = (row) => {
    console.log("row id "+row.id)
    navigate(`/admin/users/${row.id}`);
  };

  const handleDownload = async () => { 
    setDownloading(true);
    try {
      const resp = await downloadUsers();
      fileDownload(resp.data, `users.xlsx`);
    } catch (err) {
      console.log(err);
    } finally {
      setDownloading(false);
    }
  };

  const handleFilterChange = (e) => {
    const newFilterText = e.target.value;
    setFilterText(newFilterText);
    if (newFilterText) {
      loadFilteredData(newFilterText); 
    } else {
      loadData(0);
    }
  };

  useEffect(() => {
    loadData(0);
  }, [loadData]);

  return (
    <div>
      <input 
        type="text" 
        placeholder="ARA..." 
        value={filterText} 
        onChange={handleFilterChange} 
      />

      
      <ButtonGroup>
      <Button variant="secondary" onClick={handleDownload} disabled={downloading}>
        {downloading && <Spinner animation="border" size="sm" />} <FaFileDownload/> Kullanıcıları İndir
      </Button>

      <Button variant="primary" onClick={() => navigate('/admin/users/register')}>
          Yeni Kullanıcı Ekle
        </Button>
      </ButtonGroup>
      <DataTable
        title="Kullanıcılar"
        columns={columns}
        data={users} 
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        onRowClicked={handleRowClicked}
      />
    </div>
  );
};

export default AdminUsers;
