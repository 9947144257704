import React from 'react'
import Slider from '../../components/user/home/slider/slider'
import UserTemplate from '../../templates/user-template'
import Spacer from '../../components/common/spacer/spacer'
import WhatWeDo from '../../components/user/about/what-we-do/what-we-do'
import WhoWeAre from '../../components/user/about/who-we-are/who-we-are'

const HomePage = () => {
  return (
    <UserTemplate>
      <Slider/>
      <Spacer/>
      <WhatWeDo/>
      <Spacer/>
      <WhoWeAre/>
      
    </UserTemplate>
  )
}

export default HomePage