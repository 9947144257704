import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { getKunye } from '../../../api/kunye-service';
import './admin-kunye.css';
import Loading from '../../common/loading/loading';

const AdminKunye = () => {
    const [kunyeler, setKunyeler] = useState(null);
    const [filters, setFilters] = useState({
        tc: { value: null, matchMode: 'startsWith' },
        ad: { value: null, matchMode: 'contains' },
        soyad: { value: null, matchMode: 'contains' },
        telefonNo: { value: null, matchMode: 'contains' },
        il: { value: null, matchMode: 'startsWith' },
        ilce: { value: null, matchMode: 'startsWith' },
        ihtiyacDurumu: { value: null, matchMode: 'contains' },
        calisanSayisi: { value: null, matchMode: 'contains' },
        engelliSayisi: { value: null, matchMode: 'contains' },
        evdekiNufus: { value: null, matchMode: 'contains' },
        ilkOkuldaOkuyanSayi: { value: null, matchMode: 'startsWith' },
        ortaOkuldaOkuyanSayi: { value: null, matchMode: 'startsWith' },
        lisedeOkuyanSayi: { value: null, matchMode: 'contains' },
        hijyenYardimi: { value: null, matchMode: 'contains' },
        gidaKolisiYardimi: { value: null, matchMode: 'contains' },
        giyimYrdimi: { value: null, matchMode: 'contains' },
        kavurmaYardimi: { value: null, matchMode: 'contains' },
        bezYardimi: { value: null, matchMode: 'contains' },
        bohcaYardimi: { value: null, matchMode: 'contains' },
        okulCantasiYardimi: { value: null, matchMode: 'contains' },
        butceYardimi: { value: null, matchMode: 'contains' },
        yardimAciklama: { value: null, matchMode: 'contains' }
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getKunye()
            .then((response) => {
                setKunyeler(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, []);

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(kunyeler);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'kunye');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], { type: EXCEL_TYPE });
                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const ihtiyacDurumuTemplate = (rowData) => {
        const durum = rowData.ihtiyacDurumu;
        const severity = durum === 'Acil' ? 'danger' : durum === 'Normal' ? 'info' : 'success';
        return <Tag value={durum} severity={severity} />;
    };

    const renderHeader = () => {
        return (
            <div className="table-header">
                <Button type="button" icon="pi pi-file-excel" label="Excel İndir" onClick={exportExcel} className="p-button-success" />
            </div>
        );
    };

    return (
        <div className="card">
            {loading ? (
                <Loading />
            ) : (
                <DataTable
                    value={kunyeler}
                    scrollable
                    scrollHeight="500px"
                    paginator
                    rows={15}
                    dataKey="tc"
                    filters={filters}
                    filterDisplay="row"
                    loading={loading}
                    emptyMessage="Künye bulunamadı."
                    header={renderHeader()} // Excel indirme butonu header olarak eklendi
                >
                    <Column field="tc" header="TC" filter filterPlaceholder="TC Ara" style={{ minWidth: '9rem' }} />
                    <Column field="ad" header="Ad" filter filterPlaceholder="Ad Ara" style={{ minWidth: '9rem' }} />
                    <Column field="soyad" header="Soyad" filter filterPlaceholder="Soyad Ara" style={{ minWidth: '9rem' }} />
                    <Column field="telefonNo" header="Telefon" filter filterPlaceholder="Telefon Ara" style={{ minWidth: '9rem' }} />
                    <Column field="il" header="İl" filter filterPlaceholder="İl Ara" style={{ minWidth: '8rem' }} />
                    <Column field="ilce" header="İlçe" filter filterPlaceholder="İlçe Ara" style={{ minWidth: '8rem' }} />
                    <Column field="mahalle" header="Mahalle" filter filterPlaceholder="Ara" style={{ minWidth: '9rem' }} />
                    <Column field="acikAdres" header="Açık Adres" filter filterPlaceholder="Ara" style={{ minWidth: '12rem' }} />
                    <Column field="ihtiyacDurumu" header="İhtiyaç Durumu" body={ihtiyacDurumuTemplate} filter filterPlaceholder="Durum Ara" style={{ minWidth: '8rem' }} />
                    <Column field="calisanSayisi" header="Çalışan Sayısı" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="engelliSayisi" header="Engelli Sayısı" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="evdekiNufus" header="Evdeki Nüfus" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="ilkOkuldaOkuyanSayi" header="İlkokul" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="ortaOkuldaOkuyanSayi" header="Ortaokul" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="lisedeOkuyanSayi" header="Lise" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="lisansdaOkuyanSayi" header="Lisans" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="hijyenYardimi" header="Hijyen Yardımı" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="gidaKolisiYardimi" header="Gıda Kolisi" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="giyimYrdimi" header="Giyim Yardımı" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="kavurmaYardimi" header="Kavurma Yardımı" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="bezYardimi" header="Bez Yardımı" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="bohcaYardimi" header="Bohça Yardımı" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="okulCantasiYardimi" header="Okul Çantası Yardımı" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="butceYardimi" header="Bütçe Yardımı" filter filterPlaceholder="Ara" style={{ minWidth: '8rem' }} />
                    <Column field="yardimAciklama" header="Yardım Açıklama" filter filterPlaceholder="Ara" style={{ minWidth: '12rem' }} />
                </DataTable>
            )}
        </div>
    );
};

export default AdminKunye;
