const validateTc = (tc) => {
    if (!/^[0-9]{11}$/.test(tc)) {
      return false; // 11 haneli sayı olmalı
    }
  
    const digits = tc.split("").map(Number);
  
    if (digits[0] === 0) {
      return false; // İlk hanesi 0 olamaz
    }
  
    const oddSum = digits[0] + digits[2] + digits[4] + digits[6] + digits[8];
    const evenSum = digits[1] + digits[3] + digits[5] + digits[7];
  
    // 4. kural: 1, 3, 5, 7, 9 basamaklarının toplamının 7 katından, 2, 4, 6, 8 basamaklarının toplamı çıkarılmalı.
    const rule4 = (oddSum * 7 - evenSum) % 10;
    if (rule4 !== digits[9]) {
      return false; // Sonuç 10. basamağı vermeli
    }
  
    // 5. kural: İlk 10 basamağın toplamının 10’a bölümünden kalan sayı, 11. basamak olmalı
    const sumFirst10 = digits.slice(0, 10).reduce((acc, digit) => acc + digit, 0);
    const rule5 = sumFirst10 % 10;
    if (rule5 !== digits[10]) {
      return false; // Sonuç 11. basamağı vermeli
    }
  
    return true; // Tüm kurallar sağlandıysa geçerli
  };
  
  export default validateTc;