import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { encryptedLocalStorage } from "../../../../helpers/functions/encrypt-storage";
import { question } from "../../../../helpers/functions/swal";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { logout } from "../../../../store/slices/auth-slice";
import "./user-menu.scss";


const UserMenu = () => {
  const { isUserLogin, user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    question("Çıkış", "Çıkmak istediğinizden emin misiniz?").then((result) => {
      if (result.isConfirmed) {
        dispatch(logout());
        encryptedLocalStorage.removeItem("token");
        navigate("/");/*  */
      }
    });
  };

  console.log("Kullanıcı Verisi:", user);
  return (
    <div className="user-menu">
      {isUserLogin ? (
        <Dropdown align="end">
          <Dropdown.Toggle variant="primary">
            {user.ad} {user.soyad}
          </Dropdown.Toggle>

          <Dropdown.Menu>
  {user.roles && user.roles.some(role => 
    ["Administrator", "Coordinator", "Manager"].includes(role) // Doğrudan string kullan
  ) && (
    <>
      <Dropdown.Item as={Link} to="/admin">
        Yönetim Paneli
      </Dropdown.Item>
      <Dropdown.Divider />
    </>
  )}


           <Dropdown.Item as={Link} to="/user">
              Profil
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Çıkış</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <>
          <Button variant="secondary" as={Link} to="/auth?key=login">
            Giriş
          </Button>
          <Button variant="primary" as={Link} to="/auth?key=register">
            Kayıt
          </Button>
        </>
      )}
    </div>
  );
};

export default UserMenu;
