import React, { useState } from "react";
import { useFormik } from "formik";
import { Form, Button, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import PasswordInput from "../../common/password-input/password-input";
import { getUser, login } from "../../../api/user-service";
import { encryptedLocalStorage } from "../../../helpers/functions/encrypt-storage";
import { useAppDispatch } from "../../../store/hooks";
import { loginFailed, loginSuccess } from "../../../store/slices/auth-slice";
import { useNavigate } from "react-router-dom";
import { toast } from "../../../helpers/functions/swal";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValues = {
    tc: "",
    sifre: "",
  };

  const validationSchema = Yup.object({
    tc: Yup.string().required("Lütfen TC numaranızı giriniz."),
    sifre: Yup.string().required("Lütfen şifrenizi giriniz."),
  });

  const onSubmit = async (values) => {

    setLoading(true);
    try {
      const respAuth = await login(values);
      encryptedLocalStorage.setItem("token", respAuth.data.token);

      const respUser = await getUser();
      dispatch(loginSuccess(respUser.data));
      navigate("/");

    } catch (err) {
      dispatch(loginFailed());
      toast(err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <Form noValidate onSubmit={formik.handleSubmit}  className="p-4">
      <Form.Group className="mb-3">
        <Form.Label>TC Kimlik No</Form.Label>
        <Form.Control
          {...formik.getFieldProps("tc")}
          isInvalid={formik.touched.tc && formik.errors.tc}
          isValid={formik.touched.tc && !formik.errors.tc}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.tc}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Şifre</Form.Label>
        <PasswordInput
          {...formik.getFieldProps("sifre")}
          isInvalid={formik.touched.sifre && formik.errors.sifre}
          isValid={formik.touched.sifre && !formik.errors.sifre}
          error={formik.errors.sifre}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        disabled={!(formik.dirty && formik.isValid) || loading}
      >
        {loading && <Spinner animation="border" size="sm" />} Giriş
      </Button>
    </Form>
  );
};

export default LoginForm;
